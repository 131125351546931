import React, {Component} from 'react';
import Spinner from '../../../resources/toggle-thumbnail.png';
import './sectionFour.scss';
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';
import checkIcon from '../../../resources/check-icon.png';

class SectionFour extends Component {
  constructor(props) {
	    super(props);
	    this.state = {
	      visibility: false,
        }
   }
   render(){
   	return (
		<div id='ProductSectionFour' className='ProductSectionFour'>
			<div className='ProductSectionFour-left'>
				<div id='ProductSectionFour-left-head'>The ECFM Value Proposition</div>
				<div id='ProductSectionFour-left-middle'>
					<div id='ProductSectionFour-left-middle-left'>
						Get a proven product that delivers
						<br />
						<br />
						<VisibilitySensor partialVisibility={true} onChange={(isVisible) => { this.setState({visibility: isVisible}) }}>
			         <ul className="list-delivers">
								<Fade bottom duration={1000} spy={this.state.visibility}>
									<li>
										<img src={checkIcon} alt='Check icon' className='check-icon'/> Quantifiable improvement in Free Cash Flows (FCF) and Return on
										Investment (ROI)
									</li>
								</Fade>
								<Fade bottom duration={1000} delay={1000} spy={this.state.visibility}>
									<li>
										<img src={checkIcon} alt='Check icon' className='check-icon'/>
										Significant efficiencies in underlying business processes,
										programs, and associated KPIs
									</li>
								</Fade>
								<Fade bottom duration={1000} delay={2000} spy={this.state.visibility}>
									<li>
										<img src={checkIcon} alt='Check icon' className='check-icon'/>
										An Uberized approach enabling identification, tracking, and bridging of performance gaps
									</li>
								</Fade>
								<Fade bottom duration={1000} delay={3000} spy={this.state.visibility}>
									<li>
										<img src={checkIcon} alt='Check icon' className='check-icon'/>
										Rapid, efficient, cost-effective deployment with minimum
										organizational effort
									</li>
								</Fade>
							</ul>
		         </VisibilitySensor>
					</div>
				</div>
			</div>
			<div className='ProductSectionFour-right'>
				{/* <div className='ProductSectionFour-percentage'>
					<span className='ProductSectionFour-number'>5-7%</span>
					<span className='ProductSectionFour-subtilte-number'>
						Improvement in performance within 4 to 6 months
					</span>
				</div> */}
				<img
					src={Spinner}
					id='ProductSectionFour-spinner-bg'
					alt='Spinner-img'
				/>
				{/* <div className='ProductSectionFour-term'>
					<span className='ProductSectionFour-term-value'>> 20%</span>
					<span className='ProductSectionFour-term-info'>
						Longer term improvement
					</span>
				</div> */}
			</div>
		</div>
	);
   }
	
};

export default SectionFour;
