import React from 'react';
import './SectionSeven.scss';
import ImgSrc from '../../../resources/caseStudy/06--.png';

const SectionSeven = () => {

	const handleContactUsClick = () => {
		if (typeof document !== 'undefined') {
			window.location = '/contact';
		}
	};
	return (
		<div className='ResourcesSectionSeven' id='six'>
			<div className="ResourcesSectionSeven-content">
				<div className='ResourcesSectionSeven-left'>
					<h2>
						EACIIT Creates Unique Software Product For Oil & Gas Fintech Company
					</h2>
					<p>
						This Oil & Gas Fintech company approached EACIIT to create a product
						that would provide its’ clients visibility on operational data of the
						assets under management directly, easily. EACIIT developed a unique
						product that provided the Fintech’s clients unprecedented visibility
						and superior functionality in accessing qualitative operational
						information of underlying assets.
					</p>
					<p>
						EACIIT created an Assurance and Valuation Platform combining Private
						and Public data for all US oil and gas wells. The product provided
						geospatial and time-based analysis of production and financial data.
						Production decline forecasts for forward cash flows and asset
						valuation were also made available to participant entities. Customized
						alerts and actionable insights for participants were some of the
						enhanced functionalities offered to clients.
					</p>
					<p>
						The final product that emerged equipped the Fintech with a significant
						competitive edge that enabled it to capture more clients.
					</p>
					<button className='ResourcesSectionSeven-contact-us' onClick={handleContactUsClick}>
						Contact Us
					</button>
				</div>
				<div className='ResourcesSectionSeven-right'>
					<div className="ResourcesSectionSeven-img">
						<img src={ImgSrc} alt='Market Calculation Img' className="ResourcesSectionSeven-market" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionSeven;
