import React from "react";
import { Icon, Modal } from "semantic-ui-react";
import ecfmTeaser from "../../resources/eaciit_hyperscaling_epm.mp4";
import Dots from "../../resources/dots.png";
import "./getstart.scss";

const GetStartComponent = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleDemoClick = () => {
        if (typeof document !== "undefined") {
            window.location = "/contact";
        }
    };

    return (
        <div className="getStart-bottom">
            <div className="getStart-head">Get Started Today.</div>
            <div className="getStart-content">
                <div className="getStart-disc">
                    <span>Let us open a new world of data insights, efficiencies, and opportunities for your organization</span>
                </div>
                <div className="getStart-btn-footer">
                    <button className="getStart-schedule-btn" onClick={handleDemoClick}>
                        Schedule a Demo
                    </button>
                    <Modal
                        size="large"
                        closeIcon
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        open={open}
                        trigger={
                            <button className="getStart-watch-btn">
                                Watch Video <Icon name="play circle outline" />
                            </button>
                        }
                    >
                        <Modal.Content image>
                            <video id="eaciit-video-player" className="eaciit-video-player" playsInline autoPlay controls>
                                <source src={ecfmTeaser} type="video/mp4" />
                                Your browser does not support HTML video.
                            </video>
                        </Modal.Content>
                    </Modal>
                </div>
            </div>
            <img src={Dots} className="getStart-dots-bg" alt="dots-bg" />
        </div>
    );
};

export default GetStartComponent;
