import React from 'react';
import './SectionSix.scss';
import ImgSrc from '../../../resources/caseStudy/05--.png';

const SectionSix = () => {
	return (
		<div className='ResourcesSectionSix' id='five'>
			<div className="ResourcesSectionSix-content">
				<div className='ResourcesSectionSix-left'>
					<div className="ResourcesSectionSix-img">
						<img src={ImgSrc} alt='Market Calculation Img' className="ResourcesSectionSix-market" />
					</div>
				</div>
				<div className='ResourcesSectionSix-right'>
					<h2>Oil & gas global service provider uses ECFM to integrate legacy ERPs, make financial processes more efficient</h2>
					<p>
						This Oil & Gas Global Service Provider wanted to adopt new growth
						oriented business models to reinvigorate the enterprise. However, the
						ERPs of the legacy businesses were not in sync with the Business
						Transformation model requirements. EACIIT used ECFM, its flagship
						product, to circumvent the need for creating new ERPs.
					</p>
					<p>
						ECFM enabled the client to integrate data from multiple legacy ERPs to
						provide a single General Ledger that met the new requirements. It
						combined financial & operational data and thus further enabled
						business transformation strategy. The highly automated and AI based
						EACIIT solution yielded remarkable improvement:
					</p>
					<ul>
						<li>
							The financial consolidation process improved, the client achieved
							over 40% reduction in close time
						</li>
						<li>
							The receivables management process improved, with significant
							reduction in overdue amount and DSO days
						</li>
						<li>
							The planning, forecasting and reporting process to manage business
							impact improved significantly too.
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default SectionSix;
