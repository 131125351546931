// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductSectionVideo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 550px;
  background: #1c2640;
}
.ProductSectionVideo .ProductSectionVideo-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -120px;
  width: 100%;
  max-width: 900px;
  height: 550px;
  background: #ec6927;
  border: 32px solid #ffffff;
  border-radius: 22px;
  cursor: pointer;
}
.ProductSectionVideo .ProductSectionVideo-card .ProductSectionVideo-card-title {
  font-family: Roboto;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  line-height: 75px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Product/SectionVideo/SectionVideo.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;AACD;AACC;EACC,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,cAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,0BAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACC,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,SAAA;EACA,UAAA;AACH","sourcesContent":[".ProductSectionVideo {\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-items: center;\n\tjustify-content: center;\n\tposition: relative;\n\tmin-height: 550px;\n\tbackground: #1c2640;\n\n\t.ProductSectionVideo-card {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t\tposition: absolute;\n\t\tbottom: -120px;\n\t\twidth: 100%;\n\t\tmax-width: 900px;\n\t\theight: 550px;\n\t\tbackground: #ec6927;\n\t\tborder: 32px solid #ffffff;\n\t\tborder-radius: 22px;\n\t\tcursor: pointer;\n\n\t\t.ProductSectionVideo-card-title {\n\t\t\tfont-family: Roboto;\n\t\t\tfont-size: 50px;\n\t\t\tfont-style: normal;\n\t\t\tfont-weight: 500;\n\t\t\tcolor: #ffffff;\n\t\t\tline-height: 75px;\n\t\t\tletter-spacing: 0em;\n\t\t\ttext-align: left;\n\t\t\tmargin: 0;\n\t\t\tpadding: 0;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
