import React from 'react';
import { Grid } from 'semantic-ui-react';
import './SectionTwo.scss';

const SectionTwo = () => {
	return (
		<div className='PlatformSectionTwo'>
			<h1 className='heading'>Platform Capabilities</h1>
			<p className='sub-heading'>
				The EACIIT Platform uses Automation, AI and Realtime Analytics to
				increase the effectiveness of key client processes and programs while
				reducing their effort, time and cost
			</p>
			<Grid columns={2} padded={'horizontally'} relaxed={'very'}>
				<Grid.Row>
					<Grid.Column>
						<div className='PlatformCard PlatformCardOne'>
							<h3 className='PlatformCard-title'>AI in Forecasts</h3>
							<p className='PlatformCard-text'>
								EACIIT platform can generate forecasts based on information
								within the system such as orderbook, opportunity conversion
								rates, sales pattern, invoice details, project activity, cost
								drivers and historical data. Accurate forecasts help baseline
								expectation on a realistic basis and allows for issues and
								upsides to be properly managed and delivered ahead of critical
								impact.
							</p>
						</div>
					</Grid.Column>
					<Grid.Column>
						<div className='PlatformCard PlatformCardTwo'>
							<h3 className='PlatformCard-title'>Automation in Blended Data</h3>
							<p className='PlatformCard-text'>
								EACIIT platform automates ingestion and blending of relevant
								data from various sources to provide complete information
								required to make smart decisions with full awareness of
								operational and financial impact to the Organization. This not
								only saves valuable time and effort in piecing together
								information but creates high value for the Organization in all
								customer facing and internal optimization situations.
							</p>
						</div>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row style={{ marginTop: 20 }}>
					<Grid.Column>
						<div className='PlatformCard PlatformCardThree'>
							<h3 className='PlatformCard-title'>
								Automation in Real-time Analytics and Actionable Insights
							</h3>
							<p className='PlatformCard-text'>
								EACIIT platform automates analytics and actionable insights on a
								real time basis. This provides the ability for various
								operational teams to work on issues form the get go while they
								are still in a nascent stage. This also provides significant
								opportunity for business owners to see where performance is
								hinged and to build upon these insights as tactical and
								strategic advantage.
							</p>
						</div>
					</Grid.Column>
					<Grid.Column>
						<div className='PlatformCard PlatformCardFour'>
							<h3 className='PlatformCard-title'>
								AI in Planning and Execution
							</h3>
							<p className='PlatformCard-text'>
								EACIIT platform provides seamless linkage between operational
								and financial data and systems, and critical processes of
								planning, forecasting, execution and reporting. This provides
								management teams the ability to mange KPIs by expectation and
								exception, and with the full engagement of operational and
								cross-functional teams, enabling best in class performance in
								given market constraints and opportunities.
							</p>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
};

export default SectionTwo;
