import React, { useState } from 'react';

import { useTransition, animated, config } from 'react-spring'
import { Menu, Header } from 'semantic-ui-react'
import { HashLink } from 'react-router-hash-link';

import DataExtractionImg from '../../../resources/features/DataExtraction.png'
import DataQualityControlImg from '../../../resources/features/DataQualityControl.png'
import DataSelfServiceImg from '../../../resources/features/DataSelfService.png'
import ComplexChartsImg from '../../../resources/features/ComplexCharts.png'
import TrackingBenchmarkingImg from '../../../resources/features/TrackingBenchmarking.png'
import AIDrivenImg from '../../../resources/features/AI-Driven.png'
import AutomatekeyImg from '../../../resources/features/Automatekey.png'
import AutomatedAlertsImg from '../../../resources/features/AutomatedAlerts.png'
import NativeChatImg from '../../../resources/features/NativeChat.png'
import IntegrationImg from '../../../resources/features/Integration.png'

import './SectionOne.scss';


const SectionOne = () => {

	const handleClick = () => {
		if (typeof document !== 'undefined') {
			window.location = '/solutions';
		}
	};

	return (
		<React.Fragment>
			<div className='PackageAplSectionOne'>
				<div className='PackageAplSectionOne-head'>
					<h1 className='PackageAplSectionOne-header'>
						Packaged Applications for KPI, Earnings & <br/> Free Cash Flow (FCF) Improvement
					</h1>
					<p className='PackageAplSectionOne-sub'>
						Packaged Applications are designed to optimize business performance by improving key performance indicators (KPIs), boosting earnings, and enhancing free cash flow (FCF). These AI-driven solutions provide real-time insights, helping businesses streamline operations, reduce inefficiencies, and drive better financial results. Tailored for specific industries and functions, these applications empower organizations to achieve sustainable growth and make data-driven decisions that positively impact the bottom line.

					</p>
					<button
							id='PackageAplSectionOne-schedule-a-demo'
							onClick={handleClick}
						>
							View all solutions
					</button>
				</div>
			</div>
		</React.Fragment>
)}

export default SectionOne;