import React from 'react';
import './SectionTwo.scss';
import financePlanning from '../../../resources/recognition/finance-planning.png';

const SectionTwo = () => {
	const redirectToCustomerStories = () => {
		if (typeof document !== 'undefined') {
			window.location = '/resources';
		}
	};

	return (
		<div className='RecognitionSectionTwo'>
			<h2>
				Eaciit Vyasa Pte. Ltd. is now certified by Singapore Fintech Association
				(SFA) as Financial Planning / Financial Literacy provider.
			</h2>
			<div className='RecognitionSectionTwoInner'>
				<div className='RecognitionSectionTwoInnerLeft'>
					<p>
						EACIIT is proud to announce that we are certified by Singapore
						FinTechAssociation (SFF).
						<br />
						<br />
						EACIIT is a Singapore-registered FinTech Company certified by SFA as
						a Financial Planning / Financial Literacy provider.
						<br />
						<br />
						The FinTech certification is for companies considering to be
						certified as a ‘FinTech’ under the Singapore FinTech Association and
						recognized by the Monetary Authority of Singapore (MAS).
					</p>
				</div>
				<div className='RecognitionSectionTwoInnerRight'>
					<img src={financePlanning} alt='Finance Planning' />
				</div>
			</div>
			<div className='text-center'>
				<button
					className='getStart-schedule-btn'
					onClick={redirectToCustomerStories}
				>
					Discover Customer Success Stories
				</button>
			</div>
		</div>
	);
};

export default SectionTwo;
