import React, { useState } from 'react';

import { useTransition, animated, config } from 'react-spring'
import { Menu, Header } from 'semantic-ui-react'
import { HashLink } from 'react-router-hash-link';

import DataExtractionImg from '../../../resources/features/DataExtraction.png'
import DataQualityControlImg from '../../../resources/features/DataQualityControl.png'
import DataSelfServiceImg from '../../../resources/features/DataSelfService.png'
import ComplexChartsImg from '../../../resources/features/ComplexCharts.png'
import TrackingBenchmarkingImg from '../../../resources/features/TrackingBenchmarking.png'
import AIDrivenImg from '../../../resources/features/AI-Driven.png'
import AutomatekeyImg from '../../../resources/features/Automatekey.png'
import AutomatedAlertsImg from '../../../resources/features/AutomatedAlerts.png'
import NativeChatImg from '../../../resources/features/NativeChat.png'
import IntegrationImg from '../../../resources/features/Integration.png'

import './SectionOne.scss';


const SectionOne = () => {

	const [activeItem, setactiveItem] = useState('DataCapabilities');

	const routeChange = (path) => {
		setactiveItem(path);
	};

	

	return (
		<React.Fragment>
			<div className='cloudFPASectionOne'>
				<div className='cloudFPASectionOne-head'>
					<h1 className='cloudFPASectionOne-header'>
						Cloud Financial Planning & Analysis<br/>Continuous MIS
					</h1>
					<p className='cloudFPASectionOne-sub'>
						Our solution delivers real-time financial insights and continuous MIS updates. Powered by AI, it integrates financial and operational data to automate forecasting, variance analysis, and reporting. This enables businesses to make informed decisions faster, improve performance, and adapt swiftly to market changes.
					</p>
				</div>
			</div>
		</React.Fragment>
)}

export default SectionOne;