// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sectionTwoSolutions {
  background: #e0e4fb;
  height: 90vh !important;
  width: 100%;
  padding-top: 10vh;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.sectionTwoSolutions .SolutionsSection-Discover {
  display: flex;
  justify-items: center;
  align-items: center;
  text-align: center;
}
.sectionTwoSolutions .SolutionsSection-Discover .SolutionsSections-discover-industry, .sectionTwoSolutions .SolutionsSection-Discover .SolutionsSections-discover-key-areas {
  padding: 70px;
  width: 50%;
  height: 320px;
  color: #fff;
}
.sectionTwoSolutions .SolutionsSection-Discover .SolutionsSections-discover-industry .subHeading, .sectionTwoSolutions .SolutionsSection-Discover .SolutionsSections-discover-key-areas .subHeading {
  font-size: 20px;
  font-weight: bold;
  line-height: 2;
}
.sectionTwoSolutions .SolutionsSection-Discover .SolutionsSections-discover-industry .heading, .sectionTwoSolutions .SolutionsSection-Discover .SolutionsSections-discover-key-areas .heading {
  line-height: 2.5;
  font-size: 48px;
  font-weight: bold;
}
.sectionTwoSolutions .SolutionsSection-Discover .SolutionsSections-discover-industry .discover-link, .sectionTwoSolutions .SolutionsSection-Discover .SolutionsSections-discover-key-areas .discover-link {
  font-size: 15px;
  font-weight: 500;
}
.sectionTwoSolutions .SolutionsSection-Discover .SolutionsSections-discover-industry .discover-link:hover, .sectionTwoSolutions .SolutionsSection-Discover .SolutionsSections-discover-key-areas .discover-link:hover {
  cursor: pointer;
}
.sectionTwoSolutions .SolutionsSection-Discover .SolutionsSections-discover-industry {
  background: #D9B058;
}
.sectionTwoSolutions .SolutionsSection-Discover .SolutionsSections-discover-key-areas {
  background: #1C2640;
}`, "",{"version":3,"sources":["webpack://./src/components/Solutions/SectionTwo/sectionTwo.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,iBAAA;EAEA,sBAAA;EACA,mBAAA;EACA,6BAAA;AAAJ;AAEI;EACI,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,kBAAA;AAAR;AAEQ;EACI,aAAA;EACA,UAAA;EACA,aAAA;EACA,WAAA;AAAZ;AAEY;EACI,eAAA;EACA,iBAAA;EACA,cAAA;AAAhB;AAGY;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AADhB;AAIY;EACI,eAAA;EACA,gBAAA;AAFhB;AAKY;EACI,eAAA;AAHhB;AAOQ;EACI,mBAAA;AALZ;AAQQ;EACI,mBAAA;AANZ","sourcesContent":[".sectionTwoSolutions{\n    background: #e0e4fb;\n    height: 90vh !important;\n    width: 100%;\n    padding-top: 10vh;\n    // display: flex;\n    flex-direction: column;\n    align-items: center;\n    /* justify-content: center; */\n\n    .SolutionsSection-Discover {\n        display: flex;\n        justify-items: center;\n        align-items: center;\n        text-align: center;\n\n        .SolutionsSections-discover-industry, .SolutionsSections-discover-key-areas{\n            padding: 70px;\n            width: 50%;\n            height: 320px;\n            color: #fff;\n\n            .subHeading {\n                font-size: 20px;\n                font-weight: bold;\n                line-height: 2;\n            }\n\n            .heading {\n                line-height: 2.5;\n                font-size: 48px;\n                font-weight: bold;\n            }\n\n            .discover-link {\n                font-size: 15px;\n                font-weight: 500;\n            }\n\n            .discover-link:hover{\n                cursor: pointer;\n            }\n        }\n\n        .SolutionsSections-discover-industry {\n            background: #D9B058;\n        }\n\n        .SolutionsSections-discover-key-areas {\n            background: #1C2640;\n        }\n\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
