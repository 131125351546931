import React, { useState } from 'react';

import { useTransition, animated, config } from 'react-spring'
import { Menu, Header } from 'semantic-ui-react'
import { HashLink } from 'react-router-hash-link';

import DataExtractionImg from '../../../resources/features/DataExtraction.png'
import DataQualityControlImg from '../../../resources/features/DataQualityControl.png'
import DataSelfServiceImg from '../../../resources/features/DataSelfService.png'
import ComplexChartsImg from '../../../resources/features/ComplexCharts.png'
import TrackingBenchmarkingImg from '../../../resources/features/TrackingBenchmarking.png'
import AIDrivenImg from '../../../resources/features/AI-Driven.png'
import AutomatekeyImg from '../../../resources/features/Automatekey.png'
import AutomatedAlertsImg from '../../../resources/features/AutomatedAlerts.png'
import NativeChatImg from '../../../resources/features/NativeChat.png'
import IntegrationImg from '../../../resources/features/Integration.png'

import './SectionOne.scss';


const SectionOne = () => {

	const [activeItem, setactiveItem] = useState('DataCapabilities');

	const routeChange = (path) => {
		setactiveItem(path);
	};

	

	return (
		<React.Fragment>
			<div className='FeaturesSectionOne'>
				<div className='FeaturesSectionOne-head'>
					<h1 className='FeaturesSectionOne-header'>
						End-to-End Capability on A Single Platform 
					</h1>
					<p className='FeaturesSectionOne-sub'>
						EACIIT brings you a one-stop platform that is equipped with all the functionalities you need - automation,<br /> analytics, AI, business intelligence, and collaboration – to support performance management.
					</p>
				</div>
			</div>

			<div className="FeaturesSectionOne-Menu">
		        <Menu pointing secondary>
		            <Menu.Item
		              name='DataCapabilities'
		              active={
							activeItem === 'DataCapabilities' 
					  }
					  onClick={() => routeChange('DataCapabilities')}
		            >
		              <Header as='h4'>Data Capabilities</Header>
		            </Menu.Item>
		             <Menu.Item
		              name='VisualizationAnalytics'
		              active={
							activeItem === 'VisualizationAnalytics' 
					  }
					  onClick={() => routeChange('VisualizationAnalytics')}
		            >
		              <Header as='h4'>Visualization & Analytics</Header>
		            </Menu.Item>
		             <Menu.Item
		              name='AutomationCollaboration'
		              active={
							activeItem === 'AutomationCollaboration' 
					  }
					  onClick={() => routeChange('AutomationCollaboration')}
		            >
		              <Header as='h4'>Automation & Collaboration </Header>
		            </Menu.Item>
		        </Menu>
	    	</div>

			{
				activeItem == "DataCapabilities" && 
				<div className="DataCapabilities-content-wrapper content-wrapper">
	     			<h1 className='DataCapabilities-content-header header'>
						Data Capabilities
					</h1>
	        		<div id="DataExtraction" className="section-custom DataExtraction">
			            <div className="content-wrapper-left">
			            	<div className="header1">Data Extraction & Integration</div>
			            	<div className="header2">All the data you need, all in one place</div>
			            	<div className="txt-content">
				                <p>The EACIIT Platform enables you to:</p>
				                <ul>
				                  <li><span>Ingest & orchestrate structured and unstructured data from multiple sources</span></li>
				                  <li><span>Tap into real-time, big data from internal and external sources</span></li>
				                  <li><span>Easily upload, merge and model your data</span></li>
				                  <li><span>Enrich data models by automatically blending additional data sets and user inputs</span></li>
				                </ul>
			             	</div>
			            </div>
			            <div className="content-wrapper-right">
			            	<img src={DataExtractionImg} className="img-content img-DataExtraction"  alt="img-content"/>
			            </div>
	        		</div>

	        		<div id="DataQualityControl" className="section-custom-2 DataQualityControl">
			            <div className="content-wrapper-left">
			            	<img src={DataQualityControlImg} className="img-content img-DataQualityControl"  alt="img-content"/>
			            </div>
			            <div className="content-wrapper-right">
			             	<div className="header1">Data Quality Control</div>
			            	<div className="header2">Data quality you can rely on</div>
			            	<div className="txt-content">
				                <p>The EACIIT Platform ensures:</p>
				                <ul>
				                  <li><span>Data Cleansing, </span></li>
				                  <li><span>Data Refinement</span></li>
				                  <li><span>Data Curation</span></li>
				                  <li><span>Sophisticated algorithms to establish critical meta and transaction data quality</span></li>
				                </ul>
			             	</div>
			            </div>
	        		</div>

	        		<div id="DataSelfService" className="section-custom DataSelfService">
			            <div className="content-wrapper-left">
			            	<div className="header1">Data Self-Service Use & Analytics Studio</div>
			            	<div className="header2">Access to Single Source of Truth data for all users</div>
			            	<div className="txt-content">
				                <p>The EACIIT Platform provides:</p>
				                <ul>
				                  <li><span>Single Source of Truth data for both business and technical users</span></li>
				                  <li><span>Access to data in a user-friendly manner without technical barriers</span></li>
				                  <li><span>Ability to combine and process large amounts of data from different sources</span></li>
				                  <li><span>Drag-and-drop ability to generate reports and analysis within seconds, no IT intervention required</span></li>
				                </ul>
			             	</div>
			            </div>
			            <div className="content-wrapper-right">
			            	<img src={DataSelfServiceImg} className="img-content img-DataSelfService"  alt="img-content"/>
			            </div>
	        		</div>
        		</div>
			}

			{
				activeItem == "VisualizationAnalytics" && 
				<div className="VisualizationAnalytics-content-wrapper content-wrapper">
	     			<h1 className='VisualizationAnalytics-content-header header'>
						Visualization & Analytics
					</h1>
	        		<div id="ComplexCharts" className="section-custom ComplexCharts">
			            <div className="content-wrapper-left">
			            	<div className="header1">Complex charts</div>
			            	<div className="header2">Gain the insights you need</div>
			            	<div className="txt-content">
				                <p>The EACIIT Platform equips you with game-changing visual analysis. It delivers</p>
				                <ul>
				                  <li><span>Human-centered design</span></li>
				                  <li><span>Embedded computations provide instant insight</span></li>
				                  <li><span>Unconstrained charting functionality above and beyond BI tools</span></li>
				                </ul>
			             	</div>
			            </div>
			            <div className="content-wrapper-right">
			            	<img src={ComplexChartsImg} className="img-content img-ComplexCharts"  alt="img-content"/>
			            </div>
	        		</div>

	        		<div id="TrackingBenchmarking" className="section-custom-2 TrackingBenchmarking">
			            <div className="content-wrapper-left">
			            	<img src={TrackingBenchmarkingImg} className="img-content img-TrackingBenchmarking"  alt="img-content"/>
			            </div>
			            <div className="content-wrapper-right">
			             	<div className="header1">Tracking & benchmarking</div>
			            	<div className="header2">Raise levels of performance to best-in-class</div>
			            	<div className="txt-content">
				                <p>The EACIIT Platform enables:</p>
				                <ul>
				                  <li><span>Continuous benchmarking vs. internal & external criteria</span></li>
				                  <li><span>Real-time alerts & performance updates</span></li>
				                  <li><span>Variance analysis & geospatial analytics</span></li>
				                </ul>
			             	</div>
			            </div>
	        		</div>

	        		<div id="AI-Driven" className="section-custom AI-Driven">
			            <div className="content-wrapper-left">
			            	<div className="header1">AI-Driven Business Models</div>
			            	<div className="header2">Generate accurate forecasts to drive business planning</div>
			            	<div className="txt-content">
				                <p>The EACIIT Platform provides you the capability to</p>
				                <ul>
				                  <li><span>Leverage data & AI to dramatically cut short planning cycles</span></li>
				                  <li><span>Implement optimization, scheduling, and forecasting at the most granular levels</span></li>
				                  <li><span>Conduct clustering & segmentation, simulation & predictive analytics</span></li>
				                </ul>
			             	</div>
			            </div>
			            <div className="content-wrapper-right">
			            	<img src={AIDrivenImg} className="img-content img-AI-Driven"  alt="img-content"/>
			            </div>
	        		</div>
	        	</div>
			}

	     	{
	     		activeItem == "AutomationCollaboration" && 
	     		<div className="AutomationCollaboration-content-wrapper content-wrapper">
	     			<h1 className='AutomationCollaboration-content-header header'>
						Automation & Collaboration
					</h1>
	        		<div id="Automatekey" className="section-custom Automatekey">
			            <div className="content-wrapper-left">
			            	<div className="header1">Automate key processes and initiatives</div>
			            	<div className="header2">Automate income statement, working capital and asset management processes</div>
			            	<div className="txt-content">
				                <p>The EACIIT Platform’s automation capabilities enable better:</p>
				                <ul>
				                  <li><span>Planning, forecasting & reporting</span></li>
				                  <li><span>Balancing supply chain and resources</span></li>
				                  <li><span>Manage projects and initiatives</span></li>
				                </ul>
			             	</div>
			            </div>
			            <div className="content-wrapper-right">
			            	<img src={AutomatekeyImg} className="img-content img-Automatekey"  alt="img-content"/>
			            </div>
	        		</div>

	        		<div id="AutomatedAlerts" className="section-custom-2 AutomatedAlerts">
			            <div className="content-wrapper-left">
			            	<img src={AutomatedAlertsImg} className="img-content img-AutomatedAlerts"  alt="img-content"/>
			            </div>
			            <div className="content-wrapper-right">
			             	<div className="header1">Automated alerts, reports and insights </div>
			            	<div className="header2">Reduce or eliminate human monitoring & intervention</div>
			            	<div className="txt-content">
				                <p>The EACIIT Platform automation capabilities enable:</p>
				                <ul>
				                  <li><span>Proactive management of financial, performance and operational issues</span></li>
				                  <li><span>Early warning triggers and remedial action</span></li>
				                  <li><span>Push notifications across devices</span></li>
				                </ul>
			             	</div>
			            </div>
	        		</div>

	        		<div id="NativeChat" className="section-custom NativeChat">
			            <div className="content-wrapper-left">
			            	<div className="header1">Native chat & email</div>
			            	<div className="header2">Seamless user communication</div>
			            	<div className="txt-content">
				                <p>The EACIIT Platform provides:</p>
				                <ul>
				                  <li><span>Embedded IM functionality tagged to issues & initiatives </span></li>
				                  <li><span>Automated email functionality to escalate & distribute reports</span></li>
				                </ul>
			             	</div>
			            </div>
			            <div className="content-wrapper-right">
			            	<img src={NativeChatImg} className="img-content img-NativeChat"  alt="img-content"/>
			            </div>
	        		</div>

	        		<div id="Integration" className="section-custom-2 Integration">
			            <div className="content-wrapper-left">
			            	<img src={IntegrationImg} className="img-content img-Integration"  alt="img-content"/>
			            </div>
			            <div className="content-wrapper-right">
			            	<div className="header1">Integration</div>
			            	<div className="header2">Seamless integration with other systems in the organization</div>
			            	<div className="txt-content">
				                <p>The EACIIT Platform equips you:</p>
				                <ul>
				                  <li><span>With open-source API’s to combine with ERP and other software</span></li>
				                  <li><span>To execute new business models with agility & no technical roadblocks</span></li>
				                </ul>
			             	</div>
			            </div>
	        		</div>
	        	</div>
	     	}

        	

        	

		</React.Fragment>
)}

export default SectionOne;