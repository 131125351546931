import React from 'react';
import './SectionThree.scss';
import platformInfographicImage from '../../../resources/infographic-platform-page.png';

const SectionThree = () => {
	return (
		<div className='PlatformSectionThree'>
			<div className='PlatformSectionThree-left'>
				<h1 className='title'>EACIIT Platform is the Right Solution</h1>
				<p>
					The EACIIT Platform improves Profitability and Working Capital by :
				</p>
				<ul>
					<li>
						Providing seamless integration across the entire process chain of
						planning, forecasting, execution and reporting
					</li>
					<li>
						Efficiently linking all operational and financial systems, divisions
						and business units
					</li>
					<li>Optimizing requirements, resources and supply chain</li>
					<li>Proactive management of Sales, O&M, and Financial issues</li>
				</ul>
			</div>
			<div className='PlatformSectionThree-right'>
				<img src={platformInfographicImage} alt='Platform title' />
			</div>
		</div>
	);
};

export default SectionThree;
