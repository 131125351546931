import React from 'react';
import SectionOne from './SectionOne/index.jsx';

import './cloudFPA.scss';

export class CloudFPAComponent extends React.Component {

    componentDidMount() {
        window.scrollTo(0,0)
    }


    render() {
        return (
            <React.Fragment>
                <section className="cloudFPA-component" >
                    <SectionOne  />
                </section>
            </React.Fragment>
        );
    }
}

export default CloudFPAComponent;
