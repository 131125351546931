import React, { useState } from 'react';

import { useTransition, animated, config } from 'react-spring'
import { Menu, Header } from 'semantic-ui-react'
import { HashLink } from 'react-router-hash-link';
import image1 from '../../../resources/smartCommand/img-1.png';
import image3 from '../../../resources/smartCommand/img-3.png';
import image4 from '../../../resources/smartCommand/img-4.png';

import './sectionTwo.scss';


const SectionTwo = () => {

	const [activeItem, setactiveItem] = useState('DataCapabilities');

	const routeChange = (path) => {
		setactiveItem(path);
	};

	

	return (
		<React.Fragment>
			<div className='SmartCommandSectionTwo'>
				<div className="SmartCommandSectionTwo-content-wrapper">
	        		<div id="DataExtraction" className="section-custom DataExtraction">
			            <div className="content-wrapper-left">
			            	<div className="header2">Granular view of all KPIs</div>
			            	<div className="txt-content">
				                <p>All businesses are measured in equalized terms of ROI, free cash flow, sales, profit growth and their ability to plan, forecast, and react to changes.</p>
				                
			             	</div>
			            </div>
			            <div className="content-wrapper-right">
			            	<img
								src={image1}
								alt='Product laptop'
							/>
			            </div>
	        		</div>

	        		<div id="DataQualityControl" className="section-custom-2 DataQualityControl">
			            <div className="content-wrapper-left">
			            	<img
								src={image4}
								alt='Product laptop'
							/>
			            </div>
			            <div className="content-wrapper-right">
			            	<div className="header2">Linkage in operational and financial data</div>
			            	<div className="txt-content">
				                <p>The dashboards enable AI-driven analysis, offering actionable insights for improving performance across the value chain, ensuring decisions are aligned with financial goals and operational efficiency.</p>
			             	</div>
			            </div>
	        		</div>

	        		<div id="DataSelfService" className="section-custom DataSelfService">
			            <div className="content-wrapper-left">
			            	<div className="header2">Intelligent notifications and Advanced simulation</div>
			            	<div className="txt-content">
				                <p>Smart Command Center Dashboards provide intelligent notifications by monitoring real-time data and sending alerts when key metrics deviate from predefined thresholds.
The advanced simulation capabilities allow users to model various business scenarios, predicting outcomes based on changes in operational or financial inputs. </p>
			             	</div>
			            </div>
			            <div className="content-wrapper-right txt-center">
			            	<img
								src={image3}
								alt='Product laptop'
							/>
			            </div>
	        		</div>
        		</div>
			</div>

        	

		</React.Fragment>
)}

export default SectionTwo;