import React, { useState } from 'react';

import { useTransition, animated, config } from 'react-spring'
import { Menu, Header } from 'semantic-ui-react'
import { HashLink } from 'react-router-hash-link';


import './SectionOne.scss';


const SectionOne = () => {

	return (
		<div className="databrowser-page">
			<div className='DatabrowserSectionOne'>
				<div className='DatabrowserSectionOne-head'>
					<h1 className='DatabrowserSectionOne-header'>
						Enable People to Access the Data <br/>They Need Directly, Easily
					</h1>
					<p className='DatabrowserSectionOne-sub'>
						Build a data-driven organization with the EACIIT Data Browser. Empower your people to access, explore, <br/>
						and experience data the way they want, in the depth they want, with or without technical expertise.
					</p>
				</div>
			</div>

			<div className="Databrowser-content-wrapper content-wrapper">
     			<h1 className='Databrowser-content-header header'>
					The EACIIT Data Browser Value Proposition
				</h1>
        		<div className="section-content">
		            <div className="box-list">
		            	<div className="box">
		            		<div className="box-header">Easy Access - SQL Optional! </div>
		            		<div className="box-content">The EACIIT Data Browser makes data accessible to everyone. Business users can connect to live, high-quality, row-level data directly, without any technical intervention. </div>
		            	</div>
		            	<div className="box">
		            		<div className="box-header">Data Source Agnostic – Works with Multiple Data <br/> Sources</div>
		            		<div className="box-content">Unlike DB specific tools focused on only one data source type, the EACIIT Data Browser can work with multiple data sources.</div>
		            	</div>
		            </div>
		            <div className="box-list">
		            	<div className="box">
		            		<div className="box-header">The Right Balance -  Technical Depth vs Ease-of-Use</div>
		            		<div className="box-content">Offering both ease-of-use and technical depth, the EACIIT Data Browser is designed to accommodate varied workforce skill levels. Technical users can leverage the tool to do a deep-dive, while business users can use the EACIIT Data Browser to get the information they need directly.</div>
		            	</div>
		            	<div className="box">
		            		<div className="box-header">Single Source of Truth – One View for <br/>Multiple Data Systems</div>
		            		<div className="box-content">The EACIIT Data Browser enables you to aggregate and link data from multiple NoSQL systems to create a single source of truth that can be used by both technical and business users.</div>
		            	</div>
		            </div>
		            <div className="box-list">
		            	<div className="box">
		            		<div className="box-header">Complete Visibility – Get The View You Need</div>
		            		<div className="box-content">Pre-aggregates, custom filters, row-level view, the EACIIT Data Browser enables users to experience data the way they want. <br/> Common user-specific views and queries are used to aggregate data to dashboards, and users can use efficient drill-downs from here to get the specific transactional level information they may want. <br/><br/>Users are also provided the capability to build powerful, custom filters as per need.</div>
		            	</div>
		            	<div className="box">
		            		<div className="box-header">Valid Data – Verified Numbers </div>
		            		<div className="box-content">The EACIIT Data Browser comes equipped with AI-based data, quality assessment dashboards, and curation workflows so that you can be assured of accurate, consistent, quality, trustworthy data. <br/><br/>Also, while the BI tools display charts and dashboards, this tool provides you the automation required to verify the numbers behind the visuals.</div>
		            	</div>
		            </div>
		            <div className="box-list">
		            	<div className="box">
		            		<div className="box-header">Threshold-Based Actions Enabled: Alerts, Mails</div>
		            		<div className="box-content">The EACIIT Data Browser enables you to define actions that would be triggered when certain thresholds are crossed. The threshold actions could be triggered for testing, or on critical data points, thus enabling both a strong checks and balances system, and immediate identification of outliers.</div>
		            	</div>
		            	<div className="box">
		            		<div className="box-header">Strong Security: User-Specific Access</div>
		            		<div className="box-content">Data source specific tools often do not factor in user-based roles to limit access to data. However, the EACIIT Data Browser ensures only authorized users for specific tables can access data relevant to them.</div>
		            	</div>
		            </div>
		            
        		</div>
        	</div>
		</div>
)}

export default SectionOne;