import React from 'react';
import './SectionOne.scss';
import platformHeaderImageBg from '../../../resources/platform-header-image-bg.png';
import platformHeaderImage from '../../../resources/platform-header-image-update.png';
import icon1 from '../../../resources/platform/icon1.png';
import icon2 from '../../../resources/platform/icon2.png';
import icon3 from '../../../resources/platform/icon3.png';
import icon4 from '../../../resources/platform/icon4.png';
import icon5 from '../../../resources/platform/icon5.png';
import diverse from '../../../resources/platform/fold2-1.png';
import planning from '../../../resources/platform/fold2-2.png';

const SectionOne = () => {
	return (
		<div className='PlatformSectionOne'>

			<div className='PlatformSectionOne-header'>
				<p className='PlatformSectionOne-header-1'>The proven Data Integration & Business AI Platform</p>
				<h1 className='PlatformSectionOne-header-2'>
					in Enterprise Performance Management
				</h1>
				<p className='PlatformSectionOne-sub-header'>
					Digital EPM for higher efficiencies and enhanced Enterprise Performance</p>
			</div>

			<div className='PlatformSectionOne-card'>
				<div className="box-card card-one">
					<div className="card-icon">
						<img
							src={icon1}
							alt='box-icon'
						/>
					</div>
					<div className="card-description">
						Pre-built, open source platform already proven with large, global MNCs
					</div>
				</div>
				<div className="box-card card-two">
					<div className="card-icon">
						<img
							src={icon2}
							alt='box-icon'
						/>
					</div>
					<div className="card-description">
						Data from multiple sources blended and curated into single source of truth
					</div>
				</div>
				<div className="box-card card-three">
					<div className="card-icon">
						<img
							src={icon3}
							alt='box-icon'
						/>
					</div>
					<div className="card-description">
						AI-based models, human-centered design, intuitive interfaces
					</div>
				</div>
				<div className="box-card card-four">
					<div className="card-icon">
						<img
							src={icon4}
							alt='box-icon'
						/>
					</div>
					<div className="card-description">
						Intelligent integration layer providing digitization, BI and AI enablement
					</div>
				</div>
				<div className="box-card card-five">
					<div className="card-icon">
						<img
							src={icon5}
							alt='box-icon'
						/>
					</div>
					<div className="card-description">
						Functional and technical scale to keep up with evolving business requirements
					</div>
				</div>
			</div>

			<div className='PlatformSectionOne-content'>
				<div className='left-content'>
					<div className="diverse-data">
						<div className="header-diverse">
							Diverse Data Systems
						</div>
						<div className="content-diverse"><img
							src={diverse}
							alt='box-icon'
						/></div>
					</div>
					<div className="planning-data">
						<div className="header-planning"><strong>One</strong> Planning, Reporting, <br/> Decision Support System </div>
						<div className="content-planning"><img
							src={planning}
							alt='box-icon'
						/></div>
					</div>
				</div>
				<div className='right-content'>
					<div className='card-content orange'>
						<div className='header'>
							Smart Command Center Dashboards
						</div>
						<div className='description'>
							Granular view of all KPIs<br/> 
							Linkage in operational and financial data<br/>
							Intelligent notifications<br/>
							Advanced simulation
						</div>
					</div>
					<div className='card-content yellow'>
						<div className='header'>
							Cloud FPA Continuous MIS
						</div>
						<div className='description'>
							Integration in financial and operational planning<br/>
							AI based plans & forecasts<br/>
							Agile, near perfect MIS
						</div>
					</div>
					<div className='card-content purple'>
						<div className='header'>
							Packaged Applications
						</div>
						<div className='description'>
							KPIs, Earnings, and FCF Improvement (ECFM) Tool<br/>
							Receivables, inventory, asset and production downtime management
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionOne;
