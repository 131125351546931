import React, { useState } from "react";
import { Button, Dropdown, Icon, List, Menu, Segment, Transition, Message } from "semantic-ui-react";
import { Navigate, Route, Routes } from "react-router-dom";
import Company from "../Company/index.js";
import ContactUs from "../Contact/index.jsx";
import Databrowser from "../Databrowser/index.jsx";
import Features from "../Features/index.jsx";
import Home from "../Home";
import Industry from "../Industry/index.jsx";
import KeyFocus from "../KeyFocus/index.jsx";
import Platform from "../Platform";
import Product from "../Product";
import SmartCommand from "../SmartCommand/index.js";
import CloudFPA from "../CloudFPA/index.jsx";
import PackageApplications from "../PackageApplication/index.jsx"
import Resources from "../Resourses/index.js";
import Recognition from "../Recognition/index.jsx";
import Solutions from "../Solutions";
import Technology from "../Technology/index.jsx";
import DataQualityTool from "../DataQualityTool/index.js";
import Navlogo from "../../resources/eaciit-logo.png";
import "./navBar.css";
import "./navBar.scss";

export default function NavBar() {
    const [activeItem, setactiveItem] = useState("home");
    const [openMenu, setOpenMenu] = useState(false);
    const [openPlatform, setOpenPlatform] = useState(false);
    const [openSolutions, setOpenSolutions] = useState(false);
    const [openResources, setOpenResources] = useState(false);
    const [openProduct, setOpenProduct] = useState(false);

    const navbarStyles = {
        backgroundColor: "white",
        borderRadius: "0px",
        position: "fixed",
        zIndex: "1000",
        width: "100%",
        height: "70px",
        top: "0",
        left: "0"
    };

    const logoItemStyle = {
        width: "150px",
    };

    const menuItemStyle = {
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "20px",
        color: "#1C2640",
        cursor: "pointer",
        minWidth: "120px",
    };

    const contactButtonStyle = {
        background: "#1c2640",
        borderRadius: "3px",
        padding: "10px 20px",
        float: "none",
        right: "-160px",
        color: "#ffffff !important",
    };

    const routeChange = (path) => {
        setactiveItem(path);
        if (window) {
            window.location = "/" + path;
        }
        document.getElementById("navBar").scrollIntoView();
    };

    const handleClickMenu = () => setOpenMenu(!openMenu);

    const handleOpenPlatform = () => setOpenPlatform(!openPlatform);

    const handleOpenSolutions = () => setOpenSolutions(!openSolutions);

    const handleOpenResources = () => setOpenResources(!openResources);

    const handleOpenProduct = () => setOpenProduct(!openProduct);

    const handleOpenMenu = () => {
        let element = document.getElementsByClassName("navigation__scrim")[0];
        element.classList.add("active");
        // document.querySelectorAll(".active.visible.item.dropdown.menu-item")[0].classList.add("lined")
    };

    const handleCloseMenu = () => {
        let element = document.getElementsByClassName("navigation__scrim")[0];
        element.classList.remove("active");
        // document.querySelectorAll(".active.visible.item.dropdown.menu-item")[0].classList.remove("lined")
    };

    React.useEffect(() => {
        if (typeof document !== "undefined") {
            let pathName = window.location.pathname.split("/")[1];
            setactiveItem(pathName);
        }
        // document.getElementById('platformMenuParent').onclick = function () {
        // 	routeChange('platform');
        // };
    }, []);

    //  useEffect(() => {
    // 	let unlisten

    //  	routeChange(window.location.pathname.split('/')[1])
    //     // Listen for changes to the current location.
    //     unlisten = history.listen((location, action) => {
    //       routeChange(window.location.pathname.split('/')[1])
    //     })

    //     return function cleanup() {
    //       unlisten()
    //     }
    //   }, [])

    return (
        <>
            <div id="navBar">
                <Segment id={"navBarColor"} inverted style={navbarStyles} className={openMenu ? "bg-grey" : "bg-white"}>
                    <Menu inverted secondary>
                        <div className="grouped-menu">
                            <Menu.Item
                                className="company-logo"
                                style={logoItemStyle}
                                // as={Link}
                                onClick={() => routeChange("home")}
                            >
                                <img src={Navlogo} className="navbar-logo" alt="logo-navbar" />
                            </Menu.Item>
                            <Dropdown
                                className={activeItem === "product"|| activeItem === "smart-center"|| activeItem === "cloud-fpa"|| activeItem === "package-applications" || activeItem === "databrowser" ? "active nav-list menu-item" : "nav-list menu-item"}
                                item
                                text="Products"
                                onOpen={() => {
                                    handleOpenMenu();
                                }}
                                onClose={() => {
                                    handleCloseMenu();
                                }}
                            >
                                <Dropdown.Menu className="animate fadeInDown flex-menu">
                                    <div className="left mt-10 ml-20">
                                        <Dropdown.Item active={activeItem === "smart-center"} className="px-5">
                                            <Message className="custom-msg-nav px-5" header="Smart Command Center Dashboards" 
                                             onClick={() => routeChange("smart-center")} 
                                            />
                                        </Dropdown.Item>
                                        <Dropdown.Item active={activeItem === "cloud-fpa"} className="px-5">
                                            <Message className="custom-msg-nav px-5" header="Cloud FPA, Continuous MIS" 
                                             onClick={() => routeChange("cloud-fpa")} 
                                            />
                                        </Dropdown.Item>
                                        <Dropdown.Item active={activeItem === "package-applications"} className="px-5">
                                            <Message className="custom-msg-nav px-5" header="Packaged Applications" 
                                                onClick={() => routeChange("package-applications")}
                                            />
                                        </Dropdown.Item>
                                        <Dropdown.Item active={activeItem === "product"} className="px-5">
                                            <Message className="custom-msg-nav px-5" header="ECFM" onClick={() => routeChange("product")}/>
                                        </Dropdown.Item>
                                        <Dropdown.Item active={activeItem === "databrowser"} className="px-5">
                                            <Message className="custom-msg-nav px-5" header="Data Browser" onClick={() => routeChange("databrowser")}/>
                                        </Dropdown.Item>
                                        <Dropdown.Item active={activeItem === "data-quality-tool"} className="px-5">
                                            <Message className="custom-msg-nav px-5" header="Data Quality Tool" onClick={() => routeChange("data-quality-tool")}/>
                                        </Dropdown.Item>

                                    </div>
                                    <div className="right">
                                        <Dropdown.Item>
                                            <Message className="custom-msg-nav" header="Highlights" onClick={() => routeChange("product")} content={'Continuously updated results\nPredictive analytics\nActionable insights & alerts\nShared data models\nImpact analysis & Initiatives\nUnified data browser & analytics studio\nInstant Messaging\nAutomated reports & distribution'} />
                                        </Dropdown.Item>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown
                                item
                                text="Platform"
                                className={activeItem === "platform" || activeItem === "technology" || activeItem === "features" ? "active nav-list menu-item" : "nav-list menu-item"}
                                onOpen={() => {
                                    handleOpenMenu();
                                }}
                                onClose={() => {
                                    handleCloseMenu();
                                }}
                            >
                                <Dropdown.Menu className="animate fadeInDown flex-menu">
                                    <div className="left" style={{marginTop:'0px'}}>
                                        <Dropdown.Item active={activeItem === "technology"} >
                                            <Message className="custom-msg-nav" header="By Technology" onClick={() => routeChange("technology")} content={'Single integrated Platform automating continuous Data , MIS & decision support \n\nAgility and scale\n\nSecurity & Compliance\n\nAutomated use of Data from source system'}/>
                                        </Dropdown.Item>
                                    </div>
                                    <div className="right" style={{marginTop:'0px'}}>
                                        <Dropdown.Item active={activeItem === "features"} >
                                            <Message className="custom-msg-nav" header="By Capability" onClick={() => routeChange("features")} content={'Data transformation\nEncryption@REST\nAlgorithms / ML\nAnalytical models\nVisualization\nCollaboration\nWorkflows\nReport Writer\nExcel Interface\nSecure user access'} />
                                        </Dropdown.Item>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown
                                className={activeItem === "solutions" || activeItem === "keyfocus" || activeItem === "industry" ? "active nav-list menu-item" : "nav-list menu-item"}
                                item
                                text="Solutions"
                                onOpen={() => {
                                    handleOpenMenu();
                                }}
                                onClose={() => {
                                    handleCloseMenu();
                                }}
                            >
                                <Dropdown.Menu className="animate fadeInDown flex-menu">
                                    <div className="left" style={{marginTop:'0px'}}>
                                        <Dropdown.Item active={activeItem === "keyfocus"} >
                                            <Message className="custom-msg-nav" header="By Function" onClick={() => routeChange("keyfocus")} content={'Unified data\nCustom reports\nDashboard Insights\nInitiatives and AI solutions\nPlanning and simulation\nImpact Analysis\nFCF improvement\nAnalytics Studio'}/>
                                        </Dropdown.Item>
                                    </div>
                                    <div className="right" style={{marginTop:'0px'}}>
                                        <Dropdown.Item active={activeItem === "industry"} >
                                            <Message className="custom-msg-nav" header="By Industry" onClick={() => routeChange("industry")} content={'Private Equity\nOil & Gas\nBanks\nConsumer Goods\nIndustrials\nRenewable Energy\nAviation'} />
                                        </Dropdown.Item>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown
                                className={activeItem === "resources" || activeItem === "recognition" ? "active nav-list menu-item" : "nav-list menu-item"}
                                item
                                text="Resources"
                                onOpen={() => {
                                    handleOpenMenu();
                                }}
                                onClose={() => {
                                    handleCloseMenu();
                                }}
                            >
                                <Dropdown.Menu className="animate fadeInDown">
                                    <Dropdown.Item active={activeItem === "resources"}>
                                        <Message className="custom-msg-nav" header="Customer Stories" onClick={() => routeChange("resources")} content="See how EACIIT creates value for clients" />
                                    </Dropdown.Item>
                                    <Dropdown.Item active={activeItem === "recognition"}>
                                        <Message className="custom-msg-nav" header="Recognition" onClick={() => routeChange("recognition")} content="" />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Menu.Item
                                style={menuItemStyle}
                                // name='Company'
                                className="nav-list"
                                active={activeItem === "company"}
                                // as={Link}
                                // to='/company'
                                onClick={() => routeChange("company")}
                            >
                                <span className="nav-list-text">Company</span>
                            </Menu.Item>
                        </div>
                        <div className="right-menu">
                            <Menu.Item
                                style={contactButtonStyle}
                                position="right"
                                name="Contact Us"
                                className="contact-us nav-list"
                                // as={Link}
                                // to='/contact'
                                onClick={() => routeChange("contact")}
                            />
                        </div>
                    </Menu>
                    <div className="icon-menu-list">
                        <Button className="contact-us" onClick={() => routeChange("contact")}>
                            Contact Us
                        </Button>
                        <Icon name={openMenu ? "close" : "bars"} onClick={handleClickMenu} />
                    </div>
                </Segment>
                <div className="mobile-view">
                    <Transition visible={openMenu} animation="fade down" duration={500}>
                        <List divided relaxed verticalAlign="middle" className="navBarMobileScreen">
                            {/*<List.Item
								onClick={() => routeChange('product')}
								active={activeItem === 'product'}
							>
								<List.Content>
									<List.Header>Product</List.Header>
								</List.Content>
							</List.Item>*/}

                            <List.Item active={activeItem === "product"|| activeItem === "smart-center"|| activeItem === "cloud-fpa"|| activeItem === "package-applications" || activeItem === "databrowser"}>
                                <List.Content floated="right">
                                    <Icon name={openProduct ? "angle up" : "angle down"} onClick={handleOpenProduct} />
                                </List.Content>
                                <List.Content>
                                    <List.Header>Products</List.Header>
                                </List.Content>
                                <Transition visible={openProduct} animation="fade down" duration={500}>
                                    <List.List>
                                        <List.Item 
                                            onClick={() => routeChange("smart-center")} 
                                            active={activeItem === "smart-center"}>
                                            <List.Content>
                                                <List.Header>
                                                    Smart Command Center Dashboards
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>

                                        <List.Item 
                                            onClick={() => routeChange("cloud-fpa")} 
                                            active={activeItem === "cloud-fpa"}>
                                            <List.Content>
                                                <List.Header>
                                                    Cloud FPA, Continuous MIS 
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>

                                        <List.Item 
                                            onClick={() => routeChange("package-applications")} 
                                            active={activeItem === "package-applications"}>
                                            <List.Content>
                                                <List.Header>
                                                    Packaged Applications
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item onClick={() => routeChange("product")} active={activeItem === "product"}>
                                            <List.Content>
                                                <List.Header>
                                                    ECFM
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item onClick={() => routeChange("databrowser")} active={activeItem === "databrowser"}>
                                            <List.Content>
                                                <List.Header>
                                                    Data Browser
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item onClick={() => routeChange("data-quality-tool")} active={activeItem === "data-quality-tool"}>
                                            <List.Content>
                                                <List.Header>
                                                    Data Quality Tool
                                      {/*              <span className="highlight-box">
                                                        <br />
                                                        Understand and improve quality of data
                                                    </span>*/}
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content>
                                                <List.Header>
                                                    Highlights
                                                    <span className="highlight-box">
                                                        <br />
                                                        Continuously updated results<br />
                                                        Predictive analytics<br />
                                                        Actionable insights & alerts<br />
                                                        Shared data models<br />
                                                        Impact analysis & Initiatives<br />
                                                        Unified data browser & analytics studio<br />
                                                        Instant Messaging<br />
                                                        Automated reports & distribution

                                                    </span>
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                    </List.List>
                                </Transition>
                            </List.Item>

                            <List.Item active={activeItem === "platform" || activeItem === "features" || activeItem === "technology"}>
                                <List.Content floated="right">
                                    <Icon name={openPlatform ? "angle up" : "angle down"} onClick={handleOpenPlatform} />
                                </List.Content>
                                <List.Content>
                                    <List.Header>Platform</List.Header>
                                </List.Content>
                                <Transition visible={openPlatform} animation="fade down" duration={500}>
                                    <List.List divided className="flex-menu">
                                        <List.Item onClick={() => routeChange("technology")} active={activeItem === "technology"}>
                                            <List.Content>
                                                <List.Header>
                                                    By Technology
                                                    <span className="highlight-box">
                                                        <br />
                                                        Single integrated Platform automating continuous Data , MIS & decision support <br/>
                                                        Agility and scale<br/>
                                                        Security & Compliance<br/>
                                                        Automated use of Data from source system
                                                    </span>
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item onClick={() => routeChange("features")} active={activeItem === "features"}>
                                            <List.Content>
                                                <List.Header>
                                                    By Capability
                                                    <span className="highlight-box">
                                                        <br />
                                                        Data transformation<br/>
                                                        Encryption@REST<br/>
                                                        Algorithms / ML<br/>
                                                        Analytical models<br/>
                                                        Visualization<br/>
                                                        Collaboration<br/>
                                                        Workflows<br/>
                                                        Report Writer<br/>
                                                        Excel Interface<br/>
                                                        Secure user access
                                                    </span>
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                    </List.List>
                                </Transition>
                            </List.Item>

                            <List.Item active={activeItem === "solutions" || activeItem === "industry" || activeItem === "keyfocus"}>
                                <List.Content floated="right">
                                    <Icon name={openSolutions ? "angle up" : "angle down"} onClick={handleOpenSolutions} />
                                </List.Content>
                                <List.Content>
                                    <List.Header>Solutions</List.Header>
                                </List.Content>
                                <Transition visible={openSolutions} animation="fade down" duration={500}>
                                    <List.List divided className="flex-menu">
                                        <List.Item onClick={() => routeChange("keyfocus")} active={activeItem === "keyfocus"}>
                                            <List.Content>
                                                <List.Header>
                                                    By Function
                                                    <span className="highlight-box">
                                                        <br />
                                                        Unified data<br/>
                                                        Custom reports<br/>
                                                        Dashboard Insights<br/>
                                                        Initiatives and AI solutions<br/>
                                                        Planning and simulation<br/>
                                                        Impact Analysis<br/>
                                                        FCF improvement<br/>
                                                        Analytics Studio
                                                    </span>
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item onClick={() => routeChange("industry")} active={activeItem === "industry"}>
                                            <List.Content>
                                                <List.Header>
                                                    By Industry
                                                    <span className="highlight-box">
                                                        <br />
                                                        Private Equity<br/>
                                                        Oil & Gas<br/>
                                                        Banks<br/>
                                                        Consumer Goods<br/>
                                                        Industrials<br/>
                                                        Renewable Energy<br/>
                                                        Aviation
                                                    </span>
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                    </List.List>
                                </Transition>
                            </List.Item>

                            <List.Item active={activeItem === "resources"}>
                                <List.Content floated="right">
                                    <Icon name={openResources ? "angle up" : "angle down"} onClick={handleOpenResources} />
                                </List.Content>
                                <List.Content>
                                    <List.Header>Resources</List.Header>
                                </List.Content>
                                <Transition visible={openResources} animation="fade down" duration={500}>
                                    <List.List>
                                        <List.Item onClick={() => routeChange("resources")} active={activeItem === "resources"}>
                                            <List.Content>
                                                <List.Header>
                                                    Customer Stories
                                                    <span className="highlight-box">
                                                        <br />
                                                        See how EACIIT creates value for clients
                                                    </span>
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item onClick={() => routeChange("recognition")} active={activeItem === "recognition"}>
                                            <List.Content>
                                                <List.Header>Recognition</List.Header>
                                            </List.Content>
                                        </List.Item>
                                    </List.List>
                                </Transition>
                            </List.Item>

                            <List.Item onClick={() => routeChange("company")} active={activeItem === "company"}>
                                <List.Content>
                                    <List.Header>Company</List.Header>
                                </List.Content>
                            </List.Item>
                        </List>
                    </Transition>
                </div>
            </div>
        </>
    );
}
