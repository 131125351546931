// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlatformSectionThree {
  background: #1c2640;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 18px;
  color: #ffffff;
  padding: 100px 120px;
}
.PlatformSectionThree .PlatformSectionThree-left {
  display: flex;
  width: 55%;
  flex-direction: column;
  gap: 6%;
}
.PlatformSectionThree .PlatformSectionThree-left .title {
  font-size: 38px;
  font-weight: 900;
}
.PlatformSectionThree .PlatformSectionThree-right {
  display: flex;
  width: 45%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.PlatformSectionThree .PlatformSectionThree-right img {
  width: 100%;
  max-width: 400px;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Platform/SectionThree/SectionThree.scss"],"names":[],"mappings":"AAAA;EACC,mBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,cAAA;EACA,oBAAA;AACD;AACC;EACC,aAAA;EACA,UAAA;EACA,sBAAA;EACA,OAAA;AACF;AACE;EACC,eAAA;EACA,gBAAA;AACH;AAGC;EACC,aAAA;EACA,UAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AADF;AAGE;EACC,WAAA;EACA,gBAAA;EACA,YAAA;AADH","sourcesContent":[".PlatformSectionThree {\n\tbackground: #1c2640;\n\twidth: 100%;\n\tdisplay: flex;\n\tflex-direction: row;\n\tposition: relative;\n\tfont-size: 18px;\n\tcolor: #ffffff;\n\tpadding: 100px 120px;\n\n\t.PlatformSectionThree-left {\n\t\tdisplay: flex;\n\t\twidth: 55%;\n\t\tflex-direction: column;\n\t\tgap: 6%;\n\n\t\t.title {\n\t\t\tfont-size: 38px;\n\t\t\tfont-weight: 900;\n\t\t}\n\t}\n\n\t.PlatformSectionThree-right {\n\t\tdisplay: flex;\n\t\twidth: 45%;\n\t\tflex-direction: column;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\n\t\timg {\n\t\t\twidth: 100%;\n\t\t\tmax-width: 400px;\n\t\t\theight: auto;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
