import React from 'react';
import './sectionThree.scss';
import icon1 from '../../../resources/homepage/fold2_1.png';
import icon2 from '../../../resources/homepage/fold2_2.png';
import icon3 from '../../../resources/homepage/fold2_3.png';
import icon4 from '../../../resources/homepage/fold2_4.png';
import icon5 from '../../../resources/homepage/fold2_5.png';
import icon6 from '../../../resources/homepage/fold2_6.png';
import bgImage from '../../../resources/homepage/fold2_bg.png';

const SectionThree = () => {
	const handleDiscoverKeyFocusClick = () => {
		if (typeof document !== 'undefined') {
			window.location = '/keyfocus';
		}
	};

	const handleDiscoverIndustryClick = () => {
		if (typeof document !== 'undefined') {
			window.location = '/industry';
		}
	};

	const handlePlatformClick = () => {
		if (typeof document !== 'undefined') {
			window.location = '/platform';
		}
	};

	const routeChange = (path) => {
		if (window) {
			window.location = '/' + path;
		}
		document.getElementById('navBar').scrollIntoView();
	};

	return (
		<div id='sectionThree'>
			<div id='sectionThree-content'>
				<div id='sectionThree-left'>
					<div className="sub-title">Why EACIIT</div>
					<div className="title">Imagine Enterprise Performance Management in auto-pilot mode on One Platform</div>
					<div className="description">Powered by AI-enabled applications tailored to meet your specific management information needs</div>
					<button className="btn-to-platform" onClick={handlePlatformClick}>
                        Explore the Platform
                    </button>
				</div>
				<div id='sectionThree-right'>
					<img src={bgImage} />
				</div>
			</div>
		</div>
	);
};

export default SectionThree;
