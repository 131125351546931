import React from 'react';
import NavBar from '../NavBar/index.jsx';
import Footer from '../Footer/index.jsx';
import { Outlet } from 'react-router-dom';

export class LayoutComponent extends React.Component {

	render() {
		return (
			<section>
				<NavBar />
				<main>
			        <Outlet />
			     </main>
				<section className='footer-section-bottom'>
					<Footer />
				</section>
			</section>
		);
	}
}

export default LayoutComponent;
