import React, { useState, useEffect } from 'react';
import './SectionSix.scss';
import jeffImg from '../../../resources/jeff.png';
import michaelImg from '../../../resources/michael.png';
import richardImg from '../../../resources/richard.png';
import chrisImg from '../../../resources/chris.png';

const data = [
	{
		quote: `“Never take your eyes off the cash flow because it’s the lifeblood of business.”`,
		author: 'Sir Richard Branson',
		authorImageUrl: richardImg,
	},
	{
		quote: `“We were always focused on our profit and loss statement. But cash flow was not a regularly discussed topic. It was as if we were driving along, watching only the speedometer, when in fact we were running out of gas.”`,
		author: 'Michael Dell',
		authorImageUrl: michaelImg,
	},
	{
		quote: `“Percentage margins are not one of the things we are seeking to optimize. It’s the absolute dollar free cash flow per share that you want to maximize, and if you can do that by lowering margins, we would do that.”`,
		author: 'Jeff Bezos',
		authorImageUrl: jeffImg,
	},
	{
		quote: `“The fact is that one of the earliest lessons I learned in business was that balance sheets and income statements are fiction, cash flow is reality.”`,
		author: 'Chris Chocola',
		authorImageUrl: chrisImg,
	},
];

const SectionSix = () => {
	const [activeSlide, setActiveSlide] = useState(0);
	const { quote, author, authorImageUrl } = data[activeSlide];

	useEffect(() => {
		const intervalId = setInterval(() => {
			if (activeSlide === data.length - 1) {
				setActiveSlide(0);
			} else setActiveSlide(activeSlide + 1);
		}, 5000);
		return () => clearInterval(intervalId);
	}, [activeSlide]);

	return (
		<div className='sectionSix-Product'>
			<p className='sectionSix-Product-title'>
				Free Cash Flow- The Sustainability Metric
			</p>
			<div className='sectionSix-Product-content'>
				<div className='sectionSix-Product-content-left'>
					<div className='author-image-background' />
					<img src={authorImageUrl} alt={author} />
				</div>
				<div className='sectionSix-Product-content-right'>
					<h4 className='sectionSix-Product-content-right-quote'>{quote}</h4>
					<p className='sectionSix-Product-content-right-author'>— {author}</p>
					<div className='sectionSix-Product-content-right-dots-section'>
						{data.map((item, index) => (
							<div
								className={
									index === activeSlide ? 'slide-dot active' : 'slide-dot'
								}
								key={index}
								onClick={() => setActiveSlide(index)}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionSix;
