import React from 'react';
import SectionOne from './SectionOne/index.jsx';

import "../../responsive.scss";

export class ContactComponent extends React.Component {

    componentDidMount() {
        window.scrollTo(0,0)
    }


    render() {
        return (
            <React.Fragment>
                <section>
                    <SectionOne  />
                </section>
            </React.Fragment>
        );
    }
}

export default ContactComponent;
