import React from 'react';
import SectionOne from './SectionOne/index.jsx';
import SectionTwo from './SectionTwo/index.jsx';

import './company.scss';

const CompanyComponent = () => {
	return (
		<>	<div className="CompanyPage">
				<SectionOne />
				<SectionTwo />
			</div>
		</>
	);
};

export default CompanyComponent;
