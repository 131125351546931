import React from 'react';
import SectionOne from './SectionOne/index.jsx';
import SectionTwo from './SectionTwo/index.jsx';
import SectionThree from './SectionThree/index.jsx';
import SectionFour from './SectionFour/index.jsx';
import SectionFive from './SectionFive/index.jsx';
import SectionVideo from './SectionVideo/index.js';
import SectionSix from './SectionSix/index.jsx';

import './product.scss';

export class ProductComponent extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div className='ProductPage'>
				<section>
					<SectionOne />
				</section>
				<section>
					<SectionVideo />
				</section>
				<section>
					<SectionTwo />
				</section>
				<section>
					<SectionThree />
				</section>
				<section>
					<SectionFour />
				</section>
				<section>
					<SectionFive />
				</section>
				<section>
					<SectionSix />
				</section>
			</div>
		);
	}
}

export default ProductComponent;
