import React from 'react';
import finTechCertificate from '../../../resources/recognition/eaciit-fintech-certificate.png';
import './SectionOne.scss';

const SectionOne = () => {
	return (
		<div className='RecognitionSectionOne'>
			<img src={finTechCertificate} alt='EACIIT FinTech Certificate' />
		</div>
	);
};

export default SectionOne;
