import React from 'react';
import './sectionThree.scss';
import section3Icon1 from '../../../resources/product-3-1.png';
import section3Icon2 from '../../../resources/product-3-2.png';
import section3Icon3 from '../../../resources/product-3-3.png';

const SectionThree = () => {
	return (
		<div id='ProductSectionThree' className='sectionThree-Product'>
			<div id='ProductSectionThree-content'>
				<div id='ProductSectionThree-head'>What ECFM Provides</div>
				<div id='ProductSectionThree-subhead'>
					High impact for any industry and business situation; Leverages
					existing technology, data, and processes
				</div>
				<div id='ProductSectionThree-card-section'>
					<div id='ProductSectionThree-card1'>
						<div id='ProductSectionThree-card1-top'>
							<img src={section3Icon1} alt='product-section3-card1' />
						</div>
						<div id='ProductSectionThree-card1-para'>
							Improves EBITDA and working capital at a rapid pace across all
							aspects of the business
						</div>
					</div>

					<div id='ProductSectionThree-card2'>
						<div id='ProductSectionThree-card2-top'>
							<img
								src={section3Icon2}
								id='ProductSectionThree-card2-img'
								alt='product-section3-card2'
							/>
						</div>
						<div id='ProductSectionThree-card2-para'>
							Shifts ownership on improving KPIs from senior management to
							frontline operations
						</div>
					</div>

					<div id='ProductSectionThree-card3'>
						<div id='ProductSectionThree-card3-top'>
							<img
								src={section3Icon3}
								id='ProductSectionThree-card3-img'
								alt='product-section3-card3'
							/>
						</div>
						<div id='ProductSectionThree-card3-para'>
							Essential tool to enhance performance at operational and
							management levels
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionThree;
