import React from 'react';
import SectionOne from './SectionOne/index.jsx';
import SectionTwo from './SectionTwo/index.jsx';

import './recognition.scss';


const Recognition = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.getElementById('navBar').scrollIntoView();
	}, []);

	return (
		<div className='recognition'>
			<SectionOne />
			<SectionTwo />
		</div>
	);
};

export default Recognition;
