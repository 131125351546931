import React from 'react'
import "./sectionThree.scss";
import DeliveryApproachImage from "../../../resources/delivery-approach.png";
import DeliveryApproachImage1 from "../../../resources/delivery-approach-1.png";
import DeliveryApproachImage2 from "../../../resources/delivery-approach-2.png";

const SectionThree = () => {
    return (
        <div id="SolutionsSectionThree" className="sectionThree-Solutions">
          <div id="SolutionsSectionThree-content">
            <div id="SolutionsSectionThree-head">The EACIIT Delivery Approach</div>
            <div id="SolutionsSectionThree-subhead">Shifts the journey : Working Back from the Business Ambition to Digital Solutions
            </div>

            <div id="SolutionsSectionThree-card-section">
              {/*<img src={DeliveryApproachImage} id="SolutionsSectionThree-delivery-approach-image" alt="card-img" />*/}
              <img src={DeliveryApproachImage1} id="SolutionsSectionThree-delivery-approach-image-1" alt="card-img" />
              <img src={DeliveryApproachImage2} id="SolutionsSectionThree-delivery-approach-image-2" alt="card-img" />
            </div>

            <div id="SolutionsSectionThree-left-middle-bottom">
              Maximum Value Derived quickly with Minimum Organizational and IT effort
            </div>
          </div>
        </div>
    )
}

export default SectionThree
