import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import dqtVideoThumbnailImage from "../../../resources/dqt-video-thumbnail.png";
import ecfmTeaser from "../../../resources/dqt_teaser.mp4";
import playIcon from "../../../resources/play-icon.png";
import "./SectionTwo.scss";

const SectionTwo = () => {
    const [open, setOpen] = useState(false);

    return (
        <div className="DataQualityToolSectionTwo">
            <Modal
                size="large"
                closeIcon
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                trigger={
                    <div className="DataQualityToolSectionTwo-card">
                        <img src={playIcon} className="play-button" alt="Play icon" width={160} />
                        <img src={dqtVideoThumbnailImage} className="DataQualityToolSectionTwo-card-img" alt="DQT Video Thumnail" />
                    </div>
                }
            >
                <Modal.Content image>
                    <video id="eaciit-video-player" className="eaciit-video-player" playsInline autoPlay controls>
                        <source src={ecfmTeaser} type="video/mp4" />
                        Your browser does not support HTML video.
                    </video>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default SectionTwo;
