import React from 'react';
import './SectionThree.scss';
import ImgSrc from '../../../resources/caseStudy/02-.png';

const SectionThree = () => {
	return (
		<div className='ResourcesSectionThree' id='two'>
			<div className="ResourcesSectionThree-content">
				<div className='ResourcesSectionThree-left'>
					<h2>ECFM generates $7 bn impact for oil & gas super major</h2>
					<p>
						Faced with a declining commodity price environment, client was looking
						to significantly reduce its upstream spend other than from headcount
						reduction or supply chain renegotiation. EACIIT deployed its flagship
						product, ECFM, to create a benchmarking, collaboration and ideation
						performance improvement solution for reducing time and cost of
						upstream activities, generating an impact of more than $7 bn.
					</p>
					<p>
						Automation and AI in planning, forecasting and reporting upstream
						activities ushered in unprecedented visibility across the spectrum of
						activities, and reduction in process lead times of over 80%
					</p>
				</div>
				<div className='ResourcesSectionThree-right'>
					<div className="ResourcesSectionThree-img">
						<img src={ImgSrc} alt='Market Calculation Img' className="ResourcesSectionThree-market" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionThree;
