import React from 'react';
import './sectionFive.scss';
import img1 from '../../../resources/global-super-major-oil-and-gas-company.png';
import img2 from '../../../resources/independant-power-producer-in-india.png';

const SectionFive = () => {

	const handleClickLearnMoreGlobal = (link) => {
		window.location = '/resources#two';
	};
	const handleClickLearnMoreIndependent = (link) => {
		window.location = '/resources#four';
	};

	return (
		<div id='ECFMBenefit' className='sectionFive-Product'>
			<h3 className='sectionFive-Product-bottom-title'>ECFM Success Stories</h3>
			<div className='ProductSectionFive-content'>
				<div className='ProductSectionFive-content-card' onClick={handleClickLearnMoreGlobal}>
					<img src={img1} alt='Global Super Major Oil and Gas Company' />
					<div className='ProductSectionFive-content-card-content'>
						<h4>
							Global Super Major
							<br />
							Oil and Gas Company
						</h4>
						<p>Time and cost of upstream activities reduced rapidly</p>
						<a href='/resources#two'>Learn more -></a>
					</div>
				</div>
				<div className='ProductSectionFive-content-card'>
					<img src={img2} alt='Global Super Major Oil and Gas Company' />
					<div className='ProductSectionFive-content-card-content' onClick={handleClickLearnMoreIndependent}>
						<h4>Independent Power Producer</h4>
						<p>
							Project IRR & Cash Flow
							<br />
							improved
						</p>
						<a href='/resources#four'>Learn more -></a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionFive;
