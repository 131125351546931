import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import './sectionFour.scss';
import card1 from '../../../resources/oil-and-gas-card.png';
import card2 from '../../../resources/banking-and-finance-card.png';
import card3 from '../../../resources/pure-equity-card.png';
import oilGasDiscountImg from '../../../resources/20-per-card-yellow.png';
import bankingAndFinanceDiscountImg from '../../../resources/20-per-card-red.png';
import pureEquityDiscountImg from '../../../resources/20-per-card-blue.png';
import VisibilitySensor from 'react-visibility-sensor';
import clientImg from '../../../resources/homepage/fold4_clients_logo.png';
import arrow from "../../../resources/arrow-2.png";
import check1 from "../../../resources/homepage/check-1.png";
import check2 from "../../../resources/homepage/check-2.png";
import check3 from "../../../resources/homepage/check-3.png";
import check4 from "../../../resources/homepage/check.png";

class SectionFour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibilityOilandGas: false,
      visibilityBankingFinancial: false,
      visibilityPrivate: false,
  }

  this.handleDiscoverProductClick = this.handleDiscoverProductClick.bind(this)
  this.handleContactClick = this.handleContactClick.bind(this)
  }


  

  handleDiscoverProductClick() {
    if (typeof document !== 'undefined') {
      window.location = '/product';
    }
  };

  handleContactClick() {
    if (typeof document !== 'undefined') {
      window.location = '/contact';
    }
  };

  render() {
    return (
      <div id='sectionFour-wrapper'>
        <div className="sectionFour-client">
          <div className="heading">Extensive Global Client Experience</div>
          <div className="clientImg"><img src={clientImg}/></div>
          <div className="stories-link"><a href='/resources'>See Customer Success Stories <img id="sectionFour-arrow" src={arrow} alt="section-img-arrow"/></a></div>
        </div>
        <div id='sectionFour'>
          <div id='sectionFour-Head'>
            Our Products
          </div>
          <div className="card-wrapper">
            <div className="card-one-section-four">
              <div className="header">Smart Command Centre Dashboard</div>
              <div className="lines"/>
              <div className="content">
                <div className="list">
                  <img src={check1}/> Granular view of all KPIs
                </div>
                <div className="list">
                  <img src={check1}/> Linkage in operational and financial data
                </div>
                <div className="list">
                  <img src={check1}/> Intelligent notifications
                </div>
                <div className="list">
                  <img src={check1}/> Advanced simulation
                </div>
              </div>
            </div>
            <div className="card-two-section-four">
              <div className="header">Cloud FPA, Automated MIS</div>
              <div className="lines"/>
              <div className="content">
                <div className="list">
                  <img src={check2}/> Integration in financial   and operational planning
                </div>
                <div className="list">
                  <img src={check2}/> AI based plans &  forecasts
                </div>
                <div className="list">
                  <img src={check2}/> Agile, near perfect MIS
                </div>
              </div>
            </div>
            <div className="card-three-section-four">
              <div className="header">Packaged Applications </div>
              <div className="lines"/>
              <div className="content">
                <div className="list">
                  <img src={check3}/> KPIs, Earnings, and FCF Improvement (ECFM) Tool
                </div>
                <div className="list">
                  <img src={check3}/> Receivables, inventory, asset and production downtime management
                </div>
              </div>
            </div>
          </div>
          <div className="card-wrapper">
            <div className="card-four-section-four">
              <div className="header">Common Features</div>
              <div className="lines"/>
              <div className="content">
                <div className="list">
                  <img src={check4}/> Continuously updated results
                </div>
                <div className="list">
                  <img src={check4}/> Predictive analytic
                </div>
                <div className="list">
                  <img src={check4}/> Actionable insights and alerts
                </div>
                <div className="list">
                  <img src={check4}/> Shared data models
                </div>
              </div>
              <div className="content">
                <div className="list">
                  <img src={check4}/> Impact analysis and Initiatives
                </div>
                <div className="list">
                  <img src={check4}/> Unified data browser and analytics studio
                </div>
                <div className="list">
                  <img src={check4}/> Instant Messaging
                </div>
                <div className="list">
                  <img src={check4}/> Automated reports and distribution
                </div>
              </div>
            </div>
          </div>

          <button className="btn-contact" onClick={this.handleContactClick}>
             Contact Us
          </button>
        </div>
      </div>
    );
  }
};

export default SectionFour;