import React from "react";
import "./sectionTwo.scss";

import { Icon } from 'semantic-ui-react'

const SectionTwo = () => {
  const handleDiscoverIndustryClick = () => {
    if (typeof document !== 'undefined') {

    window.location = '/industry';
    }
  }

  const handleDiscoverKeyFocusClick = () => {
    if (typeof document !== 'undefined') {

    window.location = '/keyfocus';
    }
  }
  return (
    <div id="SolutionsSectionTwo" className="sectionTwoSolutions">
      <div className="SolutionsSection-Discover">
        <div className = "SolutionsSections-discover-industry">
          <div className="subHeading">Discover EACIIT Solutions For Your</div>
          <div className="heading">Industry</div>
          <div className="discover-link" onClick={handleDiscoverIndustryClick}>Discover More <Icon name='arrow right' /></div>
        </div>
        <div className = "SolutionsSections-discover-key-areas">
          <div className="subHeading">Discover EACIIT Solutions For Your</div>
          <div className="heading">Key Focus Areas</div>
          <div className="discover-link" onClick={handleDiscoverKeyFocusClick}>Discover More <Icon name='arrow right' /></div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
