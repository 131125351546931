// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body{
    margin: 0;
    padding: 0;
    font-family: "Roboto", Roboto !important;
    color: #1C2640 !important;
    line-height: 1.86;
}

.App{
/*    height: 100vh;*/
    width: 100%;
/*    overflow-y: scroll;*/
    overflow-x: hidden;
}
section{
    height: auto;
    width: 100%;
}

.footer-section-bottom{
    /*border-top: 3px solid #414141;*/
    background: rgba(157, 170, 241, 0.32);
}


`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;IACI,SAAS;IACT,UAAU;IACV,wCAAwC;IACxC,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;AACA,qBAAqB;IACjB,WAAW;AACf,0BAA0B;IACtB,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,iCAAiC;IACjC,qCAAqC;AACzC","sourcesContent":["\nbody{\n    margin: 0;\n    padding: 0;\n    font-family: \"Roboto\", Roboto !important;\n    color: #1C2640 !important;\n    line-height: 1.86;\n}\n\n.App{\n/*    height: 100vh;*/\n    width: 100%;\n/*    overflow-y: scroll;*/\n    overflow-x: hidden;\n}\nsection{\n    height: auto;\n    width: 100%;\n}\n\n.footer-section-bottom{\n    /*border-top: 3px solid #414141;*/\n    background: rgba(157, 170, 241, 0.32);\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
