import axios from 'axios';
import React, { useState } from 'react';
import { Form, Icon, Input } from 'semantic-ui-react';
import ContactIcon from '../../../resources/call.png';
import MailIcon from '../../../resources/email-icon.png';
import MapIcon from '../../../resources/map-icon.png';
import './sectionOne.scss';

import { CONFIG } from '../../../config'

const SectionOne = () => {
	const [data, setData] = useState({
		firstName: '',
		lastName: '',
		role: '',
		company: '',
		industry: '',
		email: '',
		subject: '',
		message: '',
	});

	const handleChange = (e, { name, value }) =>
		setData((prevData) => ({
			...prevData,
			[name]: value,
		}));

	const handleSubmit = () => {
		if (firstName === '') {
			alert('Please enter first name');
		} else if (email === '') {
			alert('Please enter email id');
		} else {
			axios
				.post(`${CONFIG.api_base_url}eaciit-website/contact`, data)
				.then((response) => {
					console.log(response);
					if (response.status === 200) {
						alert('Form submitted successfully');
						setData({
							firstName: '',
							lastName: '',
							role: '',
							company: '',
							industry: '',
							email: '',
							subject: '',
							message: '',
						});
					} else {
						alert('Something went wrong. Please try again');
					}
				})
				.catch((error) => {
					console.log(error.response);
					alert('Something went wrong. Please try again');
				});
		}
	};

	const redirectToLinkedIn = (name) => {
		let url = '';
		switch (name) {
			case 'navdeep':
				url = 'https://www.linkedin.com/in/navdeep-jain-98915a20/';
				break;
			case 'eaciit':
				url = 'https://www.linkedin.com/company/eaciit/';
				break;
			case 'usa':
				url = 'https://www.linkedin.com/in/namita-anand-025ab18/';
				break;
			default:
				break;
		}
		window.open(url, '_blank').focus();
	};

	const {
		firstName,
		lastName,
		role,
		company,
		industry,
		email,
		subject,
		message,
	} = data;

	return (
		<div className='ContactUsSectionOne'>
			<div className='ContactUsSectionOne-content'>
				<div className='ContactUsSectionOne-left'>
					<h1 className='title'>Contact Us</h1>
					<div className='concact-form'>
						<Form onSubmit={handleSubmit}>
							<Form.Group widths='equal'>
								<Form.Input
									fluid
									name='firstName'
									label='First name'
									size='large'
									placeholder='First name'
									value={firstName}
									onChange={handleChange}
								/>
								<Form.Input
									fluid
									name='lastName'
									label='Last name'
									size='large'
									placeholder='Last name'
									value={lastName}
									onChange={handleChange}
								/>
							</Form.Group>
							<Form.Field
								size='large'
								name='role'
								placeholder='Role'
								id='form-input-control-error-role'
								control={Input}
								label='Role'
								className='form-input'
								value={role}
								onChange={handleChange}
							/>
							<Form.Field
								size='large'
								name='company'
								placeholder='Company'
								id='form-input-control-error-company'
								control={Input}
								label='Company'
								className='form-input'
								value={company}
								onChange={handleChange}
							/>
							<Form.Field
								size='large'
								name='industry'
								placeholder='Industry'
								id='form-input-control-error-industry'
								control={Input}
								label='Industry'
								className='form-input'
								value={industry}
								onChange={handleChange}
							/>
							<Form.Field
								size='large'
								name='email'
								placeholder='Email Address'
								id='form-input-control-error-email'
								control={Input}
								label='Email Address'
								className='form-input'
								value={email}
								onChange={handleChange}
							/>
							<Form.Field
								size='large'
								name='subject'
								placeholder='Subject'
								id='form-input-control-error-subject'
								control={Input}
								label='Subject'
								className='form-input'
								value={subject}
								onChange={handleChange}
							/>
							<Form.TextArea
								label='Your Message'
								name='message'
								className='form-input'
								size='large'
								placeholder='Your Message'
								rows='6'
								value={message}
								onChange={handleChange}
							/>
							<Form.Button className='submit-button'>Submit Form</Form.Button>
						</Form>
					</div>
				</div>
				<div className='ContactUsSectionOne-right'>
					<div className='contact-information'>
						<div className='head-office'>
							<div className='header'>
								<img src={MapIcon} className='contactUs-map-icon' alt='map-icon'/>
								<span className='title-header'>Head Office Singapore</span>
							</div>
							<div className='header-detail'>
								EACIIT Vyasa PTE. LTD.
								<br />
								30 Cecil Street,
								<br />
								#19-08 Prudential Tower,
								<br />
								Singapore - 049712
							</div>
							<div
								className='connect-with-us'
								onClick={() => redirectToLinkedIn('eaciit')}
							>
								<Icon name='linkedin square' /> <span>Connect with us!</span>
							</div>
						</div>
						<div className='head-office' style={{ marginTop: '10px' }}>
							<div className='header'>
								<img src={MailIcon} className='contactUs-mail-icon' alt='mail-icon'/>
								<span className='title-header title-header-email' style={{ marginLeft: '-5px' }}>
									enquiries@eaciit.com
								</span>
							</div>
						</div>
						<div className='head-office' style={{ marginTop: '10px' }}>
							<div className='header'>
								<img src={ContactIcon} className='contactUs-call-icon' alt='call-icon'/>
								<span className='title-header title-header-call'>Primary Contact</span>
							</div>
							<div className='header-detail'>
								Navdeep Jain
								<br />
								navdeep@eaciit.com
								<br />
								Tel: +6592329682 | +19143090694
								<br />
							</div>
							<div
								className='connect-with-us'
								onClick={() => redirectToLinkedIn('navdeep')}
							>
								<Icon name='linkedin square' />{' '}
								<span>Connect with Navdeep</span>
							</div>
						</div>
					</div>
					<hr className='divider' />
					<div className='contact-information'>
						<div className='regional-office'>
							<div className='header'>
								<span className='title-header'>Regional Offices</span>
							</div>
							<div className='header-detail'>
								<span style={{ color: '#EC6927' }}>Houston – USA</span>
								<br />
								EACIIT LLC,
								<br />
								Neal Jajoo
								<br />
								neal@eaciit.com
								<br />
								Tel: 832-428-2105
								<br />
							</div>
							<div
								className='connect-with-us'
								style={{ marginBottom: '20px' }}
								onClick={() => redirectToLinkedIn('usa')}
							>
								<Icon name='linkedin square' />
							</div>
							<div className='header-detail'>
								<span style={{ color: '#EC6927' }}>Delhi – India</span>
								<br />
								Primary Contact
								<br />
								Namita Anand
								<br />
								namita@eaciit.com
								<br />
								Tel: +919953160132
								<br />
							</div>
							<div
								className='connect-with-us'
								onClick={() => redirectToLinkedIn('usa')}
							>
								<Icon name='linkedin square' />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionOne;
