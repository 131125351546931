import React, { useEffect } from 'react';
import './SectionOne.scss';
import headerBackgroundImg from '../../../resources/world-wide-clients.png';

const SectionOne = () => {

	const counterAnim = (qSelector, start = 0, end, duration = 1000) => {
		 const target = document.querySelector(qSelector);
		 let startTimestamp = null;
		 const step = (timestamp) => {
		  if (!startTimestamp) startTimestamp = timestamp;
		  const progress = Math.min((timestamp - startTimestamp) / duration, 1);
		  target.innerText = Math.floor(progress * (end - start) + start);
		  if (progress < 1) {
		   window.requestAnimationFrame(step);
		  }
		 };
		 window.requestAnimationFrame(step);
	};

	useEffect(() => {
		 counterAnim("#count1", 0, 8, 1000);
		 counterAnim("#count2", 0, 10, 1500);
		 counterAnim("#count3", 0, 100, 2000);
	}, [])

	return (
		<div className='CompanySectionOne'>
			<div className='CompanySectionOne-left'>
				<h1 className='CompanySectionOne-left-heading'>Welcome to EACIIT</h1>
				<p className='CompanySectionOne-left-text'>
					EACIIT was founded in 2013 with a mission to provide smart software
					solutions that made it easier for Companies to enhance performance and
					collaboration across the organization.
				</p>
				<p className='CompanySectionOne-left-text'>
					We started with a platform, that provided end-to-end capabilities, ranging from data extraction to process automation. Along the way, we developed our flagship product, ECFM. This provides companies a proven and effective way to benchmark, ideate, and improve performance across the organization.
				</p>
			</div>
			<div className='CompanySectionOne-right'>
				<img src={headerBackgroundImg} alt='world wide companies' />
			</div>
			<div className='CompanySectionOne-count-div'>
				<div className='CompanySectionOne-count-div-inner'>
					<h3 id="count1">&nbsp;</h3>
					<p>+ Years</p>
				</div>
				<div className='CompanySectionOne-count-div-inner'>
					<h3 id="count2">&nbsp;</h3>
					<p>+ Industries</p>
				</div>
				<div className='CompanySectionOne-count-div-inner'>
					<h3 id="count3">&nbsp;</h3>
					<p>+ Use Cases</p>
				</div>
			</div>
		</div>
	);
};

export default SectionOne;
