import React from 'react'

import ImageBg from '../../../resources/keyFocus/fold1_bg.png'
import ImageTitle from '../../../resources/keyFocus/fold1_title.png'
import Image1 from '../../../resources/keyFocus/1.png'
import Image2 from '../../../resources/keyFocus/2.png'
import Image3 from '../../../resources/keyFocus/3.png'


import ImageSlide1 from '../../../resources/keyFocus/swiper/Group-390.png'
import ImageSlide2 from '../../../resources/keyFocus/swiper/Group-391.png'
import ImageSlide3 from '../../../resources/keyFocus/swiper/Group-392.png'
import ImageSlide4 from '../../../resources/keyFocus/swiper/Group-393.png'
import ImageSlide5 from '../../../resources/keyFocus/swiper/Group-394.png'
import ImageSlide6 from '../../../resources/keyFocus/swiper/Group-395.png'
import ImageSlide7 from '../../../resources/keyFocus/swiper/Group-396.png'
import ImageSlide8 from '../../../resources/keyFocus/swiper/Group-397.png'
import ImageSlide9 from '../../../resources/keyFocus/swiper/Group-398.png'
import ImageSlide10 from '../../../resources/keyFocus/swiper/Group-400.png'
import ImageSlide11 from '../../../resources/keyFocus/swiper/Group-401.png'
import ImageSlide12 from '../../../resources/keyFocus/swiper/Group-402.png'
import ImageSlide13 from '../../../resources/keyFocus/swiper/Group-403.png'
import ImageSlide14 from '../../../resources/keyFocus/swiper/Group-404.png'
import ImageSlide15 from '../../../resources/keyFocus/swiper/Group-405.png'
import ImageSlide16 from '../../../resources/keyFocus/swiper/Group-406.png'
import ImageSlide17 from '../../../resources/keyFocus/swiper/Group-407.png'
import ImageSlide18 from '../../../resources/keyFocus/swiper/Group-408.png'

import "./sectionOne.scss";



const SectionOne = () => {

  return (
    <React.Fragment>
      <div id="keyFocusSectionOne" className="keyFocusSectionOne">
        <div className="keyFocusSectionOne-left">
          <div id="keyFocusSectionOne-left-head">Automation and AI enabled multiple horizontal solutions</div>
          <div id="keyFocusSectionOne-left-middle">
            <div id="keyFocusSectionOne-left-middle-left">
              Seeking to drive digital transformation across the organization? Trying to improve specific KPIs & processes? 
EACIIT provides you targeted solutions to realise your business ambition. We use an integrated product, service and consulting approach to deliver customized solutions to drive improvements in your key focus areas.
Our start point is your business ambition. Years of expertise and experience help us understand it. We then leverage our high-impact product and full-service platform to construct easily deployable, high-value solutions that deliver the benefit case with speed, scale and agility.<br />
            </div>
          </div>
        </div>

        <div className="keyFocusSectionOne-right">
          <img alt="key-focus-img" src={ImageTitle} className="keyFocus-image-title"/>
          <img alt="key-focus-img" src={ImageBg} className="keyFocus-image"/>
        </div>
      </div>
      <div className="keyFocusSectionOne-content-carousel">
        <div className="marquee top-carousel">
          <div>
        {/*<marquee behavior="alternate" direction="left" scrollamount="5" className="top-carousel">*/}
            <img alt="key-focus-img" src={ImageSlide1} className="img-carousel"/>
            <img alt="key-focus-img" src={ImageSlide2} className="img-carousel"/>
            <img alt="key-focus-img" src={ImageSlide3} className="img-carousel"/>
            <img alt="key-focus-img" src={ImageSlide4} className="img-carousel"/>
            <img alt="key-focus-img" src={ImageSlide5} className="img-carousel"/>
            <img alt="key-focus-img" src={ImageSlide6} className="img-carousel"/>
            <img alt="key-focus-img" src={ImageSlide7} className="img-carousel"/>
            <img alt="key-focus-img" src={ImageSlide8} className="img-carousel"/>
            <img alt="key-focus-img" src={ImageSlide9} className="img-carousel"/>
          </div>
        </div>
        {/*</marquee>*/}
        <div className="marquee bottom-carousel">
          <div>
        {/*<marquee behavior="alternate" direction="right" scrollamount="5" className="bottom-carousel">*/}
            <img alt="key-focus-img" src={ImageSlide10} className="img-carousel"/>
            <img alt="key-focus-img" src={ImageSlide11} className="img-carousel"/>
            <img alt="key-focus-img" src={ImageSlide12} className="img-carousel"/>
            <img alt="key-focus-img" src={ImageSlide13} className="img-carousel"/>
            <img alt="key-focus-img" src={ImageSlide14} className="img-carousel"/>
            <img alt="key-focus-img" src={ImageSlide15} className="img-carousel"/>
            <img alt="key-focus-img" src={ImageSlide16} className="img-carousel"/>
            <img alt="key-focus-img" src={ImageSlide17} className="img-carousel"/>
            <img alt="key-focus-img" src={ImageSlide18} className="img-carousel"/>
          </div>
        {/*</marquee>*/}
        </div>

      </div>
      <div className="keyFocusSectionOne-content-wrapper">
        <div className="section-custom section-custom-1">
            <div className="content-wrapper-left">
              <div className="txt-content">
                <ul>
                  <li>Profitability analysis, reporting, planning, forecasting, efficient working capital management</li>
                  <li>Sales analysis: outlet and SKU level, channel and geo, price and volume, single view of customer</li>
                  <li>Production planning based on demand forecast, capacity constraints and supply chain</li>
                </ul>
              </div>
            </div>
            <div className="content-wrapper-right">
              <img alt="key-focus-img" src={Image1} className="img-content"/>
            </div>
        </div>
        <div className="section-custom section-custom-2">
            <div className="content-wrapper-left">
              <img alt="key-focus-img" src={Image2} className="img-content"/>
            </div>
            <div className="content-wrapper-right">
                <div className="txt-content-two">
                  <ul>
                    <li>Opportunity and risk management</li>
                    <li>Monitor long term contracts, investments, currency exposure, credit risk</li>
                    <li>Comprehensive project and construction management solutions</li>
                  </ul>
                </div>
            </div>
        </div>
        <div className="section-custom section-custom-1">
            <div className="content-wrapper-left">
              <div className="txt-content">
                <ul>
                  <li>Audit and Compliance across multiple statutory jurisdictions</li>
                  <li>Niche Solutions: Root Cause Analysis; Geospatial Analysis; Data Quality assessment</li>
                  <li>Smart maintenance based on OEM schedules and conditions of use</li>
                  <li>IOT Solutions for extending Asset MTBF, and several others </li>
                </ul>
              </div>
            </div>
            <div className="content-wrapper-right">
              <img alt="key-focus-img" src={Image3} className="img-content"/>
            </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SectionOne;
