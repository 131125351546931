let  api_base_url = '';

if (typeof document !== 'undefined') {
    api_base_url  = window && window['runConfig'] && window['runConfig'].api_base_url;
}

export const CONFIG = {
    api_base_url
}

