import React from "react";
import SectionOne from "./SectionOne/index.jsx";
import SectionTwo from "./SectionTwo/index.jsx";
// import SectionThree from "./SectionThree/index.jsx";
import SectionFour from "./SectionFour/index.jsx";
import "./DataQualityTool.scss";

const DataQualityTool = () => {
    return (
        <div className="DataQualityToolPage">
            <section>
                <SectionOne />
            </section>
            <section>
                <SectionTwo />
            </section>
            {/* <section>
                <SectionThree />
            </section> */}
            <section>
                <SectionFour />
            </section>
        </div>
    );
};

export default DataQualityTool;
