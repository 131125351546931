import React from 'react';
import './SectionFour.scss';

const SectionFour = () => {
	const handleFeaturesClick = () => {
		window.location = '/features';
	};

	const handleTechnologyClick = () => {
		window.location = '/technology';
	};
	
	return (
		<div className='PlatformSectionFour'>
			{/*<div className='PlatformSectionFour-left'>
				<h1 className='title'>Get Started Today</h1>
				<p>
					Let us open a new world of data insights, efficiencies and
					opportunities for your organisation.
				</p>
			</div>
			<div className='PlatformSectionFour-right'>
				<button className='schedule-btn'>Schedule a Demo</button>
				<button className='watch-video-btn'>Watch Video</button>
			</div>*/}
			<div className="PlatformSectionFour-Discover">
        		<div className = "PlatformSectionFour-discover-functionality">
		            <div className="subHeading">THE FUNCTIONALITY</div>
		            <div className="heading">Automation, AI, BI, Analytics, COLLABORATION, and More</div>
		            <button className='discover-link' onClick={handleFeaturesClick}>Discover More</button>
        		</div>
		        <div className = "PlatformSectionFour-discover-technology">
		            <div className="subHeading">THE TECHNOLOGY</div>
		            <div className="heading">Cutting Edge, Open Source <br/>Delivering Speed, Scale & Security</div>
		            <button className='discover-link' onClick={handleTechnologyClick}>Discover More</button>
		        </div>
		    </div>
		</div>
	);
};

export default SectionFour;
