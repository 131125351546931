// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DataQualityToolSectionOne {
  padding: 120px 180px 250px;
  background: #f9ebd3;
  height: auto;
  width: 100%;
}
.DataQualityToolSectionOne .DataQualityToolSectionOne-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}
.DataQualityToolSectionOne .DataQualityToolSectionOne-head .DataQualityToolSectionOne-header {
  font-family: Roboto;
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: center;
}
.DataQualityToolSectionOne .DataQualityToolSectionOne-head .DataQualityToolSectionOne-sub {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 20px;
}
.DataQualityToolSectionOne .DataQualityToolSectionOne-head .register-now-btn {
  width: 200px;
  height: 45px;
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/DataQualityTool/SectionOne/SectionOne.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA;AACR;AACQ;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AACZ;AAEQ;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;AAAZ;AAGQ;EACI,YAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AADZ","sourcesContent":[".DataQualityToolSectionOne {\n    padding: 120px 180px 250px;\n    background: #f9ebd3;\n    height: auto;\n    width: 100%;\n\n    .DataQualityToolSectionOne-head {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        padding-top: 40px;\n\n        .DataQualityToolSectionOne-header {\n            font-family: Roboto;\n            font-size: 48px;\n            font-weight: 400;\n            line-height: 56px;\n            letter-spacing: 0em;\n            text-align: center;\n        }\n\n        .DataQualityToolSectionOne-sub {\n            font-family: Roboto;\n            font-size: 18px;\n            font-weight: 400;\n            line-height: 25px;\n            letter-spacing: 0em;\n            text-align: center;\n            margin-top: 20px;\n        }\n\n        .register-now-btn {\n            width: 200px;\n            height: 45px;\n            font-size: 18px;\n            font-weight: 400;\n            margin-top: 10px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
