import React, { useState } from 'react';
import Navlogo from '../../resources/logo-mini.png';
import './footer.scss';
import GetStart from '../GetStart/index.jsx';

const FooterComponent = () => {
	const [isHome, setIsHome] = useState();

	const routeChange = (path) => {
		if (window) {
			let pathName = '/' + path;
			let withHash = pathName.includes('#');

			//check if path contain hash
			if (window.location.hash && withHash) {
				let parentPathName = pathName.split('#')[0].replace(/\//g, ''); //get main path target without slash
				let windowPathName = window.location.pathname.replace(/\//g, ''); //get main path current location without slash

				if (parentPathName === windowPathName) {
					//compare path target with current path
					window.location = pathName; // change current path
					window.location.reload(true); // need to reload the page for pointing target div
				} else {
					window.location = pathName;
				}
			} else {
				window.location = pathName;
			}
		}
		document.getElementById('navBar').scrollIntoView();
	};

	React.useEffect(() => {
		if (typeof document !== 'undefined') {
			let isHome = window.location.pathname.includes('home');
			setIsHome(isHome);
		}
	}, []);

	return (
		<>
			<div className='footer'>
				{!isHome && (
					<div className='footer-get-started'>
						<GetStart />
					</div>
				)}

				<div className='section-bottom'>
					<div className='section-footer-group'>
						<div className='header-logo'>
							<img className='footer-img' src={Navlogo} alt='footer-logo' />

							<div className='section-footer-1'>
								<div className='footer-head'>EACIIT</div>

								<a className='footer-content' href='/home/#sectionThree'>
						            Single Vendor Platform
						        </a>

						        <a className='footer-content' href='/home/#sectionTwo-wrapper'>
						            Why EACIIT
						        </a>

						        <a className='footer-content' href='/company'>
						            About Us
						        </a>

						        <a className='footer-content' href='/contact'>
						            Contact
						        </a>

						        <a className='footer-content' href='/resources'>
						            Success Stories
						        </a>
							</div>
						</div>
					</div>
					<div className='section-footer-group'>
						<div className='section-footer-2'>
							<div className='footer-head'>Product</div>
							<div
								onClick={() => {
									routeChange('product');
								}}
								to='/product'
							>
								<div className='footer-content'>ECFM</div>
							</div>
							<div
								onClick={() => {
									routeChange('databrowser');
								}}
								to='databrowser'
							>
								<div className='footer-content'>Analytics studio</div>
							</div>
							<div
								onClick={() => {
									routeChange('data-quality-tool');
								}}
								to='data-quality-tool'
							>
								<div className='footer-content'>Data Quality Tool</div>
							</div>
						</div>
						<div className='section-footer-3'>
							<div className='footer-head'>Platform</div>
							<div
								onClick={() => {
									routeChange('platform');
								}}
								to='/platform'
							>
								<div className='footer-content'>Platform Overview</div>
							</div>
							<div
								onClick={() => {
									routeChange('features');
								}}
								to='/features'
							>
								<div className='footer-content'>Functionality</div>
							</div>
							<div
								onClick={() => {
									routeChange('technology');
								}}
								to='/technology'
							>
								<div className='footer-content'>Technology</div>
							</div>
						</div>
					</div>
					<div className='section-footer-group'>
						<div className='section-footer-4'>
							<div className='footer-head'>Solutions</div>
							<div
								onClick={() => {
									routeChange('solutions');
								}}
								to='/solutions'
							>
								<div className='footer-content'>Solutions Overview</div>
							</div>
							<div
								onClick={() => {
									routeChange('industry');
								}}
								to='/industry'
							>
								<div className='footer-content'>Solutions by Industry</div>
							</div>
							<div
								onClick={() => {
									routeChange('keyfocus');
								}}
								to='/keyfocus'
							>
								<div className='footer-content'>Solutions by Key Focus Area</div>
							</div>
						</div>
						<div className='section-footer-5'>
							<div className='footer-head'>Resources</div>
							<div
								onClick={() => {
									routeChange('resources');
								}}
								to='/resources'
							>
								<div className='footer-content'>Customer Stories</div>
							</div>
							{/*<div className='footer-content'>Others</div>*/}
						</div>
					</div>
					<div className='section-footer-group'>
						<div className='section-footer-6'>
							<div className='footer-head'>Company</div>
							<div
								onClick={() => {
									routeChange('company');
								}}
								to='/company'
							>
								<div className='footer-content'>Meet the Team and Advisor</div>
							</div>
						</div>
						<div className='section-footer-7'>
							<div className='footer-head'>Contact Us</div>
						</div>
					</div>
				</div>

				<div className='footer-company'>
					<span>2021 © EACIIT Vyasa PTE. LTD | All rights Reserved</span>
				</div>
			</div>
		</>
	);
};

export default FooterComponent;
