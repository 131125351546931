import React from 'react'
import arrow from "../../../resources/arrow.png";
import map from "../../../resources/map-image.png";
import "./sectionFive.css";
// import { HashLink } from 'react-router-hash-link';
import { Modal } from 'semantic-ui-react'
import ecfmTeaser from "../../../resources/eaciit_hyperscaling_epm.mp4";

// import Navlogo from "../../../resources/navLogo.png"

const SectionFive = () => {
    const [open, setOpen] = React.useState(false)

    const handleDemoClick = () => {
        window.location = '/contact';
    };


    return (
        <div id="sectionFive" >
            
        <div id="sectionFive-top">
            <div id="sectionFive-left">
                <div id="sectionFive-left-head">
                    Get Started Today.
                </div>
                <div id="sectionFive-left-options">
                    <Modal
                      size="large"
                      closeIcon
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}
                      open={open}
                      trigger={<div id="option1">Watch Video<img id="sectionFive-arrow" src={arrow} alt="arrow"/></div>}>
                      <Modal.Content image>
                        <video id="eaciit-video-player" className="eaciit-video-player" playsInline autoPlay controls>
                            <source src={ecfmTeaser} type="video/mp4" />
                            Your browser does not support HTML video.
                        </video>
                    </Modal.Content>
                    </Modal>

                    <div id="option2" onClick={handleDemoClick}>
                        Schedule a Demo
                        <img id="sectionFive-arrow" src={arrow} alt="section-img-arrow"/>
                    </div>
                    
                    {/* <div id="option3">Discover More <img id="sectionFive-arrow" src={arrow}/></div> */}
                </div>
            </div>


                </div>

        </div>
    )
}

export default SectionFive