// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DataQualityToolSectionTwo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 550px;
  background: #1c2640;
  z-index: 4;
}
.DataQualityToolSectionTwo .DataQualityToolSectionTwo-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -200px;
  width: 100%;
  max-width: 900px;
  background: #ffffff;
  border: 32px solid #ffffff;
  border-radius: 22px;
  cursor: pointer;
}
.DataQualityToolSectionTwo .DataQualityToolSectionTwo-card .play-button {
  position: absolute;
  z-index: 2;
}
.DataQualityToolSectionTwo .DataQualityToolSectionTwo-card .DataQualityToolSectionTwo-card-img {
  width: 100%;
  height: auto;
}
.DataQualityToolSectionTwo .DataQualityToolSectionTwo-card .DataQualityToolSectionTwo-card-title {
  font-family: Roboto;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  line-height: 75px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/DataQualityTool/SectionTwo/SectionTwo.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,UAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,0BAAA;EACA,mBAAA;EACA,eAAA;AACR;AACQ;EACI,kBAAA;EACA,UAAA;AACZ;AAEQ;EACI,WAAA;EACA,YAAA;AAAZ;AAGQ;EACI,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,SAAA;EACA,UAAA;AADZ","sourcesContent":[".DataQualityToolSectionTwo {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n    min-height: 550px;\n    background: #1c2640;\n    z-index: 4;\n\n    .DataQualityToolSectionTwo-card {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        justify-content: center;\n        position: absolute;\n        top: -200px;\n        width: 100%;\n        max-width: 900px;\n        background: #ffffff;\n        border: 32px solid #ffffff;\n        border-radius: 22px;\n        cursor: pointer;\n\n        .play-button {\n            position: absolute;\n            z-index: 2;\n        }\n\n        .DataQualityToolSectionTwo-card-img {\n            width: 100%;\n            height: auto;\n        }\n\n        .DataQualityToolSectionTwo-card-title {\n            font-family: Roboto;\n            font-size: 50px;\n            font-style: normal;\n            font-weight: 500;\n            color: #ffffff;\n            line-height: 75px;\n            letter-spacing: 0em;\n            text-align: left;\n            margin: 0;\n            padding: 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
