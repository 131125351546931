import React from 'react';
import SectionOne from './SectionOne/index.jsx';
import SectionTwo from './SectionTwo/index.jsx';
import SectionThree from './SectionThree/index.jsx';
import SectionFour from './SectionFour/index.jsx';
import SectionFive from './SectionFive/index.jsx';
import SectionSix from './SectionSix/index.jsx';

import './home.scss';
import '../../responsive.scss';

export class HomeComponent extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div className='HomePage'>
				<section>
					<SectionOne />
				</section>
				<section>
					<SectionThree />
				</section>
				<section>
					<SectionFour />
				</section>
				<section>
					<SectionSix/>
				</section>
				<section>
					<SectionFive />
				</section>
			</div>
		);
	}
}

export default HomeComponent;
