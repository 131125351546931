// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResourcesSectionThree {
  width: 100%;
  background: rgb(249, 235, 211);
  color: #000000;
  position: relative;
  align-items: center;
  padding: 60px 180px 60px 180px;
}
.ResourcesSectionThree h2 {
  font-size: 30px;
  font-weight: 400;
  color: #1d1d37;
  margin-bottom: 40px;
  line-height: 1.3;
  font-family: Roboto;
  font-style: normal;
}
.ResourcesSectionThree .ResourcesSectionThree-content {
  display: flex;
  margin-top: 50px;
  margin-bottom: 30px;
  gap: 5%;
  align-items: center;
}
.ResourcesSectionThree .ResourcesSectionThree-right {
  display: flex;
  width: 45%;
  flex: 1 1;
}
.ResourcesSectionThree .ResourcesSectionThree-right .ResourcesSectionThree-market {
  width: 100%;
}
.ResourcesSectionThree .ResourcesSectionThree-left {
  display: flex;
  flex-direction: column;
  width: 55%;
  margin-top: 30px;
}
.ResourcesSectionThree .ResourcesSectionThree-left p {
  font-size: 18px;
  font-weight: 400;
  color: #1d1d37;
  line-height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/Resourses/SectionThree/SectionThree.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,8BAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,8BAAA;AACD;AACC;EACC,eAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACG,kBAAA;AACL;AAEC;EACC,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,OAAA;EACG,mBAAA;AAAL;AAEC;EACC,aAAA;EACA,UAAA;EACA,SAAA;AAAF;AACE;EAGI,WAAA;AADN;AAKC;EACC,aAAA;EACA,sBAAA;EACA,UAAA;EACA,gBAAA;AAHF;AAIE;EACC,eAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;AAFH","sourcesContent":[".ResourcesSectionThree {\n\twidth: 100%;\n\tbackground: rgba(249,235,211,255);\n\tcolor: #000000;\n\tposition: relative;\n\talign-items: center;\n\tpadding: 60px 180px 60px 180px;\n\n\th2 {\n\t\tfont-size: 30px;\n\t\tfont-weight: 400;\n\t\tcolor: #1d1d37;\n\t\tmargin-bottom: 40px;\n\t\tline-height: 1.3;\n\t\tfont-family: Roboto;\n\t    font-style: normal;\n\t}\n\n\t.ResourcesSectionThree-content{\n\t\tdisplay: flex;\n\t\tmargin-top: 50px;\n\t\tmargin-bottom: 30px;\n\t\tgap: 5%;\n    \talign-items: center;\n\t}\n\t.ResourcesSectionThree-right {\n\t\tdisplay: flex;\n\t\twidth: 45%;\n\t\tflex: 1;\n\t\t.ResourcesSectionThree-market {\n\t\t    // position: absolute;\n\t\t    // right: 0;\n\t\t    width: 100%;\n\t\t}\n\t}\n\n\t.ResourcesSectionThree-left {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\twidth: 55%;\n\t\tmargin-top: 30px;\n\t\tp {\n\t\t\tfont-size: 18px;\n\t\t\tfont-weight: 400;\n\t\t\tcolor: #1d1d37;\n\t\t\tline-height: 30px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
