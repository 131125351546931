import React from 'react';
import './sectionTwo.scss';
import iconArrow from '../../../resources/homepage/fold5_arrow.png';

const SectionTwo = () => {
	const routeChange = (path) => {
		if (window) {
			window.location = '/' + path;
		}
		document.getElementById('navBar').scrollIntoView();
	};

	return (
		<div id='sectionTwo-wrapper'>
			<div id='sectionTwo'>
				<div id='sectionTwo-head' className="text-orange">Why EACIIT</div>
				<div id='sectionTwo-card'>
					<div id='sectionTwo-card-1'>
						<div id='sectionTwo-card-1-top'>
							<img src={iconArrow} id='card-1-icon' alt='card1-icon' />
							<div id='sectionTwo-card-1-head'>
								<div id='card-1-head1'>Customer-centric Solutions</div>
							</div>
						</div>
						<div id='card-1-para'>
							Smart Command Centre dashboards, Real time actionable insights
							<br />
							<br />
							KPI / Free Cash flow impact analysis
							<br />
							<br />
							Sales and operations planning
							<br />
							<br />
							Financial planning and reporting
							<br />
							<br />
							IOT based asset management
							<br />
							<br />
							<small>...and more</small>
						</div>
					</div>
					<div id='sectionTwo-card-2'>
						<div id='sectionTwo-card-2-top'>
							<img src={iconArrow} id='card-2-icon' alt='card2-icon' />
							<div id='sectionTwo-card-2-head'>
								<div id='card-2-head1' className="text-orange">Value creating granular level analysis</div>
							</div>
						</div>
						<div id='card-2-para'>
							Growth - outlet and SKU, price and volume, channels, backlog, pipeline…<br />
							<br />
							Maximizing business flows
							<br />
							<br />
							Data, AI driven productivity
							<br />
							<br />
							Economies of scale, reducing working capital
							<br />
							<br />
							Reducing production downtime, extending asset life
							<br />
							<br />
							<small>...and more</small>
						</div>
					</div>
				</div>
				<div id='sectionTwo-ctas'>
					<button
						onClick={() => {
							routeChange('contact');
						}}
					>
						Request Demo
					</button>
				</div>
			</div>
		</div>
	);
};

export default SectionTwo;
