import React from 'react';
import SectionOne from './SectionOne/index.jsx';

import './features.scss';

export class FeaturesComponent extends React.Component {

    componentDidMount() {
        window.scrollTo(0,0)
    }


    render() {
        return (
            <React.Fragment>
                <section className="features-component" >
                    <SectionOne  />
                </section>
            </React.Fragment>
        );
    }
}

export default FeaturesComponent;
