import React from 'react';
import SectionOne from './SectionOne/index.jsx';
import SectionTwo from './SectionTwo/index.jsx';
import SectionThree from './SectionThree/index.jsx';

import './solution.scss';

export class SolutionsComponent extends React.Component {

    componentDidMount() {
        window.scrollTo(0,0)
    }


    render() {
        return (
            <React.Fragment>
                <section>
                    <SectionOne  />
                </section>
                <section>
                    <SectionTwo  />
                </section>
                <section>
                    <SectionThree />
                </section>
            </React.Fragment>
        );
    }
}

export default SolutionsComponent;
