import React from 'react';
import SectionOne from './SectionOne/index.jsx';

import './technology.scss';

export class TechnologyComponent extends React.Component {

    componentDidMount() {
        window.scrollTo(0,0)
    }


    render() {
        return (
            <React.Fragment>
                <section className="technology-div">
                    <SectionOne  />
                </section>
            </React.Fragment>
        );
    }
}

export default TechnologyComponent;
