import React from "react";
import SolutionsImage from "../../../resources/solutions.png";
import "./sectionOne.scss";

const SectionOne = () => {
  return (
    <div id="SolutionsSectionOne" className="SolutionsSectionOne">
      <div className="SolutionsSectionOne-left">
        <h1 id="SolutionsSectionOne-left-head">Any Industry, Any Size, Any Area We Deliver Your Business Ambition</h1>
        <div id="SolutionsSectionOne-left-middle">
          <div id="SolutionsSectionOne-left-middle-left">
            EACIIT offers you high-value xP&A Solutions ; and that too without the Big Tech or Consulting spend. While years of experience and expertise equip us to understand your business ambition, our high-impact product and full-service platform help us deliver it. This integrated product, service and consulting approach help us deliver powerful, customised solutions that can be implemented with speed, scale and agility.
            <br />
          </div>
        </div>
      </div>

      <div className="SolutionsSectionOne-right">
        <img src={SolutionsImage} id="SolutionsSectionOne-image-bg" alt="img-bg"/>
      </div>
    </div>
  );
};

export default SectionOne;
