import React from 'react';
import { Button, Icon, Card, Grid, Modal} from 'semantic-ui-react';
import './SectionTwo.scss';
import navdeepImg from '../../../resources/teams/navdeep-jain.png';
import sandhyaImg from '../../../resources/teams/sandhya-jain.png';
import asawariImg from '../../../resources/teams/asawari-salvi-new.png';
import arjunImg from '../../../resources/teams/arjun-dhar.png';
import namitaImg from '../../../resources/teams/namita-anand.png';
import vikasImg from '../../../resources/teams/vikas-wasudev.png';
import kapilImg from '../../../resources/teams/kapil-joneja-new.png';
import settingIcon from '../../../resources/setting.PNG';
import salesCycleIcon from '../../../resources/salesCycle.PNG';
// import aboutUsImg from '../../../resources/about-us-last-fold.png';


const SectionTwo = () => {

	const [open, setOpen] = React.useState(false)
	const [activeProfile, setActiveProfile] = React.useState("")

	const redirectToLinkedIn = (name) => {
		let url = '';
		switch (name) {
			case 'navdeep':
				url = 'https://www.linkedin.com/in/navdeep-jain-98915a20/';
				break;
			case 'asawari':
				url = 'https://www.linkedin.com/in/asawaridesaisalvi/';
				break;
			case 'arjun':
				url = 'https://www.linkedin.com/in/arjun-dhar-168a425/';
				break;
			case 'namita':
				url = 'https://www.linkedin.com/in/namita-anand-025ab18/';
				break;
			case 'sandhya':
				url = 'https://www.linkedin.com/in/sandhya-jain-aabba2181/ ';
				break;
			default:
				break;
		}
		window.open(url, '_blank').focus();
	};

	const extra = (name) => {
	  return (<Button className='schedule-demo-btn' onClick={() => openDetail(name)}>
			 Read More
		  </Button>
		)
	}

	const openDetail = (name) =>{
		setOpen(true)
		setActiveProfile(name)
	}

	const handleClose = () => { setOpen(false) }

	return (
		<div className='CompanySectionTwo'>
			<p className='description-text'>
				Today, we specialize in leveraging the ECFM product together with the
				EACIIT Platform to deliver high-value solutions to our clients. You get
				comprehensive solutions that deliver your business ambition quickly,
				effectively.
			</p>
			<p className='description-text'>
				EACIIT solutions have been deployed in multiple, large-scale, global organizations across geographies. We take pride in the fact that they have been able to deliver real, quantifiable, and significant gains for clients.
			</p>
			<div className='CompanySectionTwo-team-div'>
				<h2>Meet the Team</h2>
				<div className='CompanySectionTwo-team-list'>
					<Card
					    image={navdeepImg}
					    header='NAVDEEP JAIN'
					    meta='Founder & CEO'
					    description='Ex-CFO – one of the biggest brands in Oil & Gas​. ...'
					    extra={extra('navdeep')}
					  />
					  <Card
					    image={asawariImg}
					    header='ASAWARI SALVI'
					    meta='Business Development'
					    description='24+ years of diverse professional experience ...'
					    extra={extra('asawari')}
					  />
					  <Card
					    image={arjunImg}
					    header='ARJUN DHAR'
					    meta='Chief Technology Officer'
					    description='25+ years of experience in nuances of software ...'
					    extra={extra('arjun')}
					  />
					  <Card
					    image={sandhyaImg}
					    header='SANDHYA JAIN'
					    meta='Director, Operations'
					    description='Sandhya manages operations and is responsible for driving... '
					    extra={extra('sandhya')}
					  />
				</div>
			</div>

			<div className='CompanySectionTwo-voilet-bg-wrapper'>
				<div className='CompanySectionTwo-voilet-bg'></div>
			</div>

			{/*<div className='CompanySectionTwo-partners-div'>*/}
			<div className='CompanySectionTwo-advisor-div'>
				<h2>And the Advisors</h2>
				<div className='CompanySectionTwo-advisor-list'>
					  <Card
					    image={kapilImg}
					    header='Kapil Joneja'
					    description='Kapil has extensive experience in business development and delivery of high impact... '
					    extra={extra('kapil')}
					  />
					  {/* <Card
					    image={vikasImg}
					    header='Vikas Wasudev'
					    description='Vikas has extensive experience in business development and delivery of high impact project..'
					    extra={extra('vikas')}
					  /> */}
					  <Card
					    image={namitaImg}
					    header='Namita Anand'
					    description='Namita joined EACIIT in 2015 and has held a portfolio of responsibilities since, covering..'
					    extra={extra('namita')}
					  />
				</div>

				{/*<h2>We Are The Right Partners For You</h2>*/}
				{/*<div id="CompanySectionTwo-card">
			        <div id="CompanySectionTwo-card-1" className="card-partners">
			          <div className="icon-card"><img src={settingIcon} alt='desc-icon' /></div>
			          <div id="CompanySectionTwo-card-1-head2">ECFM : A Unique Offering</div>
			          <div id="CompanySectionTwo-card-1-para">
			          Digital solution to improve free cash flow and oprimize performance <br/><br/>
			          Single app to view and manage performance across the organization <br/> <br/>
			          Value creation above ERP and traditional BI tools

			          </div>
			        </div>
			        <div id="CompanySectionTwo-card-2" className="card-partners">
			          <div className="icon-card"><img src={salesCycleIcon} alt='desc-icon' /></div>
			          <div id="CompanySectionTwo-card-2-head2">The EACIIT Approach</div>
			          <div id="CompanySectionTwo-card-2-para">
			            Client benefit case centric methodlogy
			            <br /><br/>
			            Integrated product, service and consulting approach
			            <br /><br/>
			            Enabling speed of execution and change for our clients

			          </div>
			        </div>
			        <div id="CompanySectionTwo-card-3" className="card-partners">
			          <div className="icon-card"><img src={salesCycleIcon} alt='desc-icon' /></div>
			          <div id="CompanySectionTwo-card-3-head2">Enabling Digital Solutions</div>
			          <div id="CompanySectionTwo-card-3-para">
			            Open-source technologies provide equalized capability
			            <br /><br/>
			            APIs for seamless system and process automation
			            <br /><br/>
						Combining functional, technical and project management skills
			          </div>
			        </div>
			    </div>*/}

			</div>

			<Modal open={open} onClose={handleClose} maxWidth="sm" className="modal-company">
				<div className="content">
					<Icon className="close-btn" name="close" onClick={handleClose}/>
					{ activeProfile == "navdeep" && 
						<div className="content-profil">
							<div className="profil">
								<img src={navdeepImg} className="img-profil"/>
							</div>
							<div className="profil-description">
								<div className="header">Navdeep Jain</div>
								<div className="sub-header">Founder & CEO</div>
								<div className="description">Ex-CFO – one of the biggest brands in Oil & Gas​. Delivered significant revenue, cost and efficiency gains to clients​. Has been on the forefront of innovation in using data and analysis to drive earnings and cash flow growth in highly competitive markets​. Onboarded over 15 global brands and 100+ use cases.</div>
								<div className="linkedin">
									 <Button icon color="linkedin" onClick= {(e) => {e.preventDefault(); redirectToLinkedIn('navdeep')}}><Icon name='linkedin' /></Button>
								</div>
							</div>
						</div>
					}
					{ activeProfile == "asawari" && 
						<div className="content-profil">
							<div className="profil">
								<img src={asawariImg} className="img-profil"/>
							</div>
							<div className="profil-description">
								<div className="header">ASAWARI SALVI</div>
								<div className="sub-header">Business Development</div>
								<div className="description">24+ years of diverse professional experience covering financial services, operations and business management. ​She has worked with leading multinationals performing a spectrum of roles from business planning, strategy formulation, execution, and capital raising advisory.</div>
								<div className="linkedin">
									 <Button icon color="linkedin" onClick= {(e) => {e.preventDefault(); redirectToLinkedIn('asawari')}}><Icon name='linkedin' /></Button>
								</div>
							</div>
						</div>
					}
					{ activeProfile == "arjun" && 
						<div className="content-profil">
							<div className="profil">
								<img src={arjunImg} className="img-profil"/>
							</div>
							<div className="profil-description">
								<div className="header">ARJUN DHAR</div>
								<div className="sub-header">Chief Technology Officer</div>
								<div className="description">25+ years of experience in nuances of software development, aligning client requirements, team strengths and objectives. Core technical experiences include Go-Lang based cloud computing solutions, Big Data, Stateless Architectures, Micro Services.</div>
								<div className="linkedin">
									 <Button icon color="linkedin"onClick= {(e) => {e.preventDefault(); redirectToLinkedIn('arjun')}}><Icon name='linkedin' /></Button>
								</div>
							</div>
						</div>
					}
					{ activeProfile == "sandhya" && 
						<div className="content-profil">
							<div className="profil">
								<img src={sandhyaImg} className="img-profil"/>
							</div>
							<div className="profil-description">
								<div className="header">SANDHYA JAIN</div>
								<div className="sub-header">Director, Operations</div>
								<div className="description">Sandhya manages operations and is responsible for driving internal initiatives, processes, and development projects at EACIIT. 
A software professional with an MBA, Sandhya joined EACIIT in 2017 and has used her dual skill set – project management and software expertise – to successfully deliver several complex client projects since.
A BSc from Miranda House, Delhi University, Sandhya holds an MBA from Birla Institute of Management Technology. She has exposure to software technology and systems design and deployment from her early career. 
</div>
								<div className="linkedin">
									 <Button icon color="linkedin" onClick= {(e) => {e.preventDefault(); redirectToLinkedIn('sandhya')}}><Icon name='linkedin' /></Button>
								</div>
							</div>
						</div>
					}
					{ activeProfile == "kapil" && 
						<div className="content-profil">
							<div className="profil">
								<img src={kapilImg} className="img-profil"/>
							</div>
							<div className="profil-description">
								<div className="header">KAPIL JONEJA</div>
								<div className="sub-header">Advisor</div>
								<div className="description">Kapil has extensive experience in business development and delivery of high impact information technology solutions for global clients in the Oil and Gas industry for over 30 years. Kapil joins EACIIT as an advisor after a long stint at Schlumberger, where he was holding several senior management positions. Having worked in several geographies - Asia, Middle East, Far East, he got good understanding of local cultures which paved the way to build good relations and the gained experience helped in growing the business smoothly. In addition to experience and relationship building, he is a highly skilled project/program manager with a solid educational foundation with Masters in Computer Science from Kurukshetra University.</div>
								<div className="linkedin">
									 <Button icon color="linkedin" onClick= {(e) => {e.preventDefault(); redirectToLinkedIn('kapil')}}><Icon name='linkedin' /></Button>
								</div>
							</div>
						</div>
					}
					{ activeProfile == "vikas" && 
						<div className="content-profil">
							<div className="profil">
								<img src={vikasImg} className="img-profil"/>
							</div>
							<div className="profil-description">
								<div className="header">VIKAS WASUDEV</div>
								<div className="sub-header">Advisor</div>
								<div className="description">Vikas has extensive experience in business development and delivery of high impact projects and solutions for global clients in multiple industries. He joins EACIIT as an advisor after a long stint in senior management positions and advisory roles in highly successful enterprises in Asia, Middle East, and Africa.
Vikas has worked over 25 years in the Oil & Gas industry.  His experience includes managing complex offshore operations of Marine Spread and Oil Production Assets. Vikas is a Master Mariner License Holder from MCA United Kingdom and MBA in Energy Management from University Technology Petronas (Malaysia) in collaboration with Renewable and Sustainable Energy Institute, University of Colorado Boulder, USA.
</div>
								<div className="linkedin">
									 <Button icon color="linkedin" onClick= {(e) => {e.preventDefault(); redirectToLinkedIn('vikas')}}><Icon name='linkedin' /></Button>
								</div>
							</div>
						</div>
					}
					{ activeProfile == "namita" && 
						<div className="content-profil">
							<div className="profil">
								<img src={namitaImg} className="img-profil"/>
							</div>
							<div className="profil-description">
								<div className="header">NAMITA ANAND</div>
								<div className="sub-header">Advisor</div>
								<div className="description">Namita joined EACIIT in 2015 and has held a portfolio of responsibilities since, covering marketing, project implementation, and content strategy development. Currently, she advises EACIIT on marketing, brand development, and content creation.
Namita is an Economics graduate from Lady Shriram College, Delhi University, and has created content for India’s premier financial newspapers and leading companies, such as BCG and Max New York Life Insurance, during her 20+ years career. An experienced content strategy professional, Namita specializes in helping B2B technology companies refine their messaging and gain visibility via effective content.
</div>
								<div className="linkedin">
									 <Button icon color="linkedin" onClick= {(e) => {e.preventDefault(); redirectToLinkedIn('namita')}}><Icon name='linkedin' /></Button>
								</div>
							</div>
						</div>
					}
					
				</div>
		    </Modal>
		</div>
	);
};

export default SectionTwo;
