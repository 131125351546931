import React, { useState, useEffect } from 'react';
import './sectionOne.scss';
import laptopImg from '../../../resources/smartCommand/smart-command-1.png';
import Fade from 'react-reveal/Fade';


const SectionOne = () => {


	const handleDemoClick = () => {
		if (typeof document !== 'undefined') {
			window.location = '/contact';
		}
	};

	return (
		<div id='SmartCommandOne' className='SmartCommandOne'>
			<div className='SmartCommandOne-left'>
				{/*<p className='SmartCommandOne-left-company-text'>ECFM</p>*/}
				<h4 className='SmartCommandOne-left-heading'>
					Smart Command Center <br/>Dashboards at Enterprise level
				</h4>
				<div className='SmartCommandOne-left-divider' />
				<p className='SmartCommandOne-left-text'>
					Business level dashboards, data lakes, and continuous navigation panels providing real-time updates and insights for better decision-making and improved business performance.</p>
				<button
					id='SmartCommandOne-schedule-a-demo'
					onClick={handleDemoClick}
				>
					Schedule Demo
				</button>
			</div>
			<div className='SmartCommandOne-right'>
				<img
					className='SmartCommandOne-right-bg-image'
					src={laptopImg}
					alt='Product laptop'
				/>
			</div>
		</div>
	);
};

export default SectionOne;
