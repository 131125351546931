// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecognitionSectionOne {
  display: block;
  width: 100%;
  background: #ffffff;
  color: #000;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 18px;
  margin-top: 70px;
}
.RecognitionSectionOne img {
  width: 100%;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Recognition/SectionOne/SectionOne.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,WAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;AACR","sourcesContent":[".RecognitionSectionOne {\n    display: block;\n    width: 100%;\n    background: #ffffff;\n    color: #000;\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    font-size: 18px;\n    margin-top: 70px;\n\n    img {\n        width: 100%;\n        height: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
