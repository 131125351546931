import React from 'react';
import './SectionTwo.scss';
import ImgSrc from '../../../resources/caseStudy/01-.png';

const SectionTwo = () => {
	return (
		<div className='ResourcesSectionTwo' id='one'>
			<div className="ResourcesSectionTwo-content">
				<div className='ResourcesSectionTwo-left'>
					<div className="ResourcesSectionTwo-img">
						<img src={ImgSrc} alt='Market Calculation Img' className="ResourcesSectionTwo-market" />
					</div>
				</div>
				<div className='ResourcesSectionTwo-right'>
					<h2>
						EACIIT enables global bank to get in-depth performance visibility on all new initiatives on one application
					</h2>
					<p>
						An international banking major, this EACIIT client wanted to assess
						the alignment and outcome of the initiatives being launched globally
						with respect to the strategic objectives of the bank.
					</p>
					<p>
						EACIIT created a solution that gave the bank a new way to see, work
						and collaborate across a balanced set of metrics for all global lines
						of business, functions and locations. The metrics included projects
						and initiatives, impact to financial frameworks, customer engagement,
						group productivity, risks & controls, relationship manager efficiency
						and deal pipeline.
					</p>
					<p>
						An ecosystem containing all relevant data and process outputs with
						alerts, updates and simulation for forward views of performance was
						put in place enabling the bank to get. in-depth visibility into the
						impact of the initiatives. The client was able to achieve improved
						account planning, deal pipeline and conversion.
					</p>
				</div>
			</div>
		</div>
	);
};

export default SectionTwo;
