import React from 'react';
import './SectionFive.scss';
import ImgSrc from '../../../resources/caseStudy/04-.png';

const SectionFive = () => {
	return (
		<div className='ResourcesSectionFive' id='four'>
			<div className="ResourcesSectionFive-content">
				<div className='ResourcesSectionFive-left'>
					<h2>EACIIT project management solution improves project IRR & cash flow for renewable energy company</h2>
					<p>
						The client, a Renewable Energy company, with wind farms across India
						was seeking a project management solution that would help it manage
						multiple wind-farm projects through planning, commissioning and
						operations phase. It also needed IoT solutions to improve O&M
						efficiencies and reduce asset downtime.
					</p>
					<p>
						EACIIT built a comprehensive project management solution which could
						be implemented very quickly and cost-effectively by the client. It
						also developed solutions to improve efficiency and reduce asset
						downtime.
					</p>
					<p>
						Efficient project management yielded enhanced cash flow and project
						IRR. The client saw a 20% improvement in PLF within 12 months of
						initial use. The Renewable Energy company was owned by a Private
						Equity fund. The EACIIT solution facilitated seamless due diligence
						during $1b+ exit for the PE company.
					</p>
				</div>
				<div className='ResourcesSectionFive-right'>
					<div className="ResourcesSectionFive-img">
						<img src={ImgSrc} alt='Market Calculation Img' className="ResourcesSectionFive-market" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionFive;
