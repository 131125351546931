import React from 'react';
import './sectionTwo.scss';

const SectionTwo = () => {
	return (
		<div id='howECFMworks' className='sectionTwoProduct'>
			<div id='ProductSectionTwo-head'>How ECFM Works</div>
			<div id='ProductSectionTwo-subHead'>
				Benchmark, ideate, collaborate, and improve key aspects of the organization to best in class performance
			</div>
			<div id='ProductSectionTwo-card'>
				<div id='ProductSectionTwo-card-1'>
					<div id='ProductSectionTwo-card-1-head2'>Benchmark</div>
					<div id='ProductSectionTwo-card-1-para'>
						Measure current performance against internal or external criteria
						<br />
						<br />
						Evaluate performance gaps in balanced scorecards, projects, and ESG
						initiatives
						<br />
						<br />
						Continuous visibility and course control on strategies and
						initiatives
					</div>
				</div>
				<div id='ProductSectionTwo-card-2'>
					<div id='ProductSectionTwo-card-2-head2'>Ideate</div>
					<div id='ProductSectionTwo-card-2-para'>
						Engage all stakeholders in innovation and improvement plans
						<br />
						<br />
						Measure the success of new ideas
						<br />
						<br />
						Visibility on improvements realized and in the pipeline
						<br />
						<br />
						Replicate successful ideas across the Organization
					</div>
				</div>
				<div id='ProductSectionTwo-card-3'>
					<div id='ProductSectionTwo-card-3-head2'>Collaborate, Analyze</div>
					<div id='ProductSectionTwo-card-3-para'>
						Chat and IM features
						<br />
						<br />
						Repository of discussions around opportunity planning and execution
						<br />
						<br />
						Full suite of analytics that accelerate achievement of targets
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionTwo;
