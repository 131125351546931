import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from './components/Layout/index.jsx'
import Company from './components/Company/index.js'
import ContactUs from './components/Contact/index.jsx'
import Databrowser from './components/Databrowser/index.jsx'
import Features from './components/Features/index.jsx'
import Home from './components/Home'
import Industry from './components/Industry/index.jsx'
import KeyFocus from './components/KeyFocus/index.jsx'
import Platform from './components/Platform'
import Product from './components/Product'
import SmartCommand from './components/SmartCommand/index.js'
import CloudFPA  from './components/CloudFPA/index.jsx'
import PackageApplications from './components/PackageApplication/index.jsx'
import Resources from './components/Resourses/index.js'
import Recognition from './components/Recognition/index.jsx'
import Solutions from './components/Solutions'
import Technology from './components/Technology/index.jsx'
import DataQualityTool from './components/DataQualityTool/index.js'


export default function Router() {

  return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/product" element={<Product />} />
              <Route path="/data-quality-tool" element={<DataQualityTool />} />
              <Route path="/platform" element={<Platform />} />
              <Route path="/solutions" element={<Solutions />} />
              <Route path="/industry" element={<Industry />} />
              <Route path="/keyfocus" element={<KeyFocus />} />
              <Route path="/resources" element={<Resources />} />
              <Route path="/recognition" element={<Recognition />} />
              <Route path="/company" element={<Company />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/features" element={<Features />} />
              <Route path="/technology" element={<Technology />} />
              <Route path="/databrowser" element={<Databrowser />} />
              <Route path="/smart-center" element={<SmartCommand />} />
              <Route path="/cloud-fpa" element={<CloudFPA />} />
              <Route path="/package-applications" element={<PackageApplications />} />
            </Route>
          </Routes>
        </BrowserRouter>
  );
}
