import React, { useState, useEffect } from 'react';
import './sectionOne.scss';
import laptopImg from '../../../resources/product-header-laptop-with-shadow.png';
import checkIcon from '../../../resources/check-icon.png';
import Fade from 'react-reveal/Fade';

const sliderData = [
	<p>
		Quantify KPIs in terms of FCF
		<br />
		impact
	</p>,
	<p>
		Get a single repository of <br />
		initiatives
	</p>,
	<p>Track targets easily with a full suite of analytics</p>,
	<p>
		Drive improvement from the <br />
		ground up
	</p>,
	<p>
		Engage all stakeholders <br />
		directly
	</p>,
	<p>Continuously improve business efficiency and sustainability</p>,
];

const SectionOne = () => {
	const [activeSlide, setActiveSlide] = useState(0);

	useEffect(() => {
		const intervalId = setInterval(() => {
			if (activeSlide === sliderData.length - 1) {
				setActiveSlide(0);
			} else setActiveSlide(activeSlide + 1);
		}, 3000);
		return () => clearInterval(intervalId);
	}, [activeSlide]);
	

	const handleDemoClick = () => {
		if (typeof document !== 'undefined') {
			window.location = '/contact';
		}
	};

	return (
		<div id='ProductSectionOne' className='ProductSectionOne'>
			<div className='ProductSectionOne-left'>
				<p className='ProductSectionOne-left-company-text'>ECFM</p>
				<h4 className='ProductSectionOne-left-heading'>
					Manage and Improve KPIs<br/>
					Generate Higher Efficiencies<br/>
					Increase Free Cash Flow
				</h4>
				<div className='ProductSectionOne-left-divider' />
				<p className='ProductSectionOne-left-text'>
					ECFM, a new class of essential software for managing enterprise performance, helps you manage and improve operational, financial, and sustainability KPIs. It enables you to drive higher efficiencies, continuous business improvement, and increased cash flow.</p>
				<button
					id='ProductSectionOne-schedule-a-demo'
					onClick={handleDemoClick}
				>
					Schedule Demo
				</button>
			</div>
			<div className='ProductSectionOne-right'>
				<img
					className='ProductSectionOne-right-bg-image'
					src={laptopImg}
					alt='Product laptop'
				/>
				<div
					className='ProductSectionOne-right-slider-section'
					style={{
						borderColor: activeSlide % 2 === 0 ? '#ec6927' : '#1c2640',
					}}
				>
					<Fade 
						right 
						appear={true}
						duration={500}
						distance={"5%"}
						spy={activeSlide}>
						<div className="animated-slider">
							<img
								className='ProductSectionOne-right-slider-section-check-icon'
								src={checkIcon}
								alt='Check icon'
							/>
							{sliderData[activeSlide]}
						</div>
					</Fade>
					<div className='ProductSectionOne-right-slider-dots-section'>
						{sliderData.map((slide, index) => (
							<div
								key={index}
								className={
									index === activeSlide
										? 'ProductSectionOne-right-slider-dot active'
										: 'ProductSectionOne-right-slider-dot'
								}
								onClick={() => setActiveSlide(index)}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionOne;
