import React, { useState } from 'react';
import './SectionVideo.scss';
import playIcon from '../../../resources/play-icon.png';
import { Modal } from 'semantic-ui-react';
import ecfmTeaser from '../../../resources/ecfm-product-teaser.mp4';

const SectionVideo = () => {
	const [open, setOpen] = useState(false);

	return (
		<div className='ProductSectionVideo'>
			<Modal
				size='large'
				closeIcon
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
				open={open}
				trigger={
					<div className='ProductSectionVideo-card'>
						<p className='ProductSectionVideo-card-title'>
							#It’sEasierWithECFM
						</p>
						<img src={playIcon} alt='Play icon' width={160} />
					</div>
				}
			>
				<Modal.Content image>
					<video
						id='eaciit-video-player'
						className='eaciit-video-player'
						playsInline
						autoPlay
						controls
					>
						<source src={ecfmTeaser} type='video/mp4' />
						Your browser does not support HTML video.
					</video>
				</Modal.Content>
			</Modal>
		</div>
	);
};

export default SectionVideo;
