import React from "react";
import featuresImage from "../../../resources/smartdq-features.png";
import "./SectionFour.scss";

const SectionFour = () => {
    return (
        <div className="DataQualityToolSectionFour">
            <div className="DataQualityToolSectionFour-head">
                <h1 className="DataQualityToolSectionFour-header">Product Key Features</h1>
                <p className="DataQualityToolSectionFour-sub">Our Ready to use product enables decisions based on reliable data​</p>
            </div>
            <div className="DataQualityToolSectionFour-content">
                <div className="DataQualityToolSectionFour-content-left">
                    <ul className="DataQualityToolSectionFour-content-left-features-list">
                        <li>Interactive AI tool that enables users to assess and improve data quality on a continuous basis​</li>
                        <li>Accesses and profiles large sets of data in various Enterprise Data Lakes (EDLs)</li>
                        <li>Extensive quality assessments criteria from a range of downstream functions and users</li>
                        <li>Recommend business rules and categorize them in different dimensions​</li>
                        <li>Generates data quality dashboards by business domain, project and dimensions on an instantaneous and continuous basis</li>
                        <li>Built-in issue management and remediation process for improvement in data quality and automated emails for issue resolutions.</li>
                        <li>Exceptionally detailed error analysis</li>
                        <li>Customize existing data quality rules without any additional coding</li>
                    </ul>
                </div>
                <div className="DataQualityToolSectionFour-content-right">
                    <img src={featuresImage} alt="SmartDQ features" className="DataQualityToolSectionFour-content-right-img" />
                </div>
            </div>
        </div>
    );
};

export default SectionFour;
