import React, { useState, useEffect } from 'react'
import { useTransition, animated, config } from 'react-spring'


import Industry1 from '../../../resources/industry-1.png'
import Industry2 from '../../../resources/industry-2.png'
import Industry3 from '../../../resources/industry-3.png'
import Industry4 from '../../../resources/industry-4.png'

import OilAndGasImg from '../../../resources/industry/oilGas-new.png'
import RenewableEnergyImg from '../../../resources/industry/renewableEnergy-new.png'
import BankingFinancialImg from '../../../resources/industry/bankingFinancial-new.png'
import PrivateEquityImg from '../../../resources/industry/privateEquity-new.png'
import OthersImg from '../../../resources/industry/others-new.png'

import { Menu, Header } from 'semantic-ui-react'

import { HashLink } from 'react-router-hash-link';

import "./sectionOne.scss";

const slides = [
  { id: 0, url: Industry1, className: 'industry-bg-1', to:"/industry/#oilAndGas" },
  { id: 1, url: Industry2, className: 'industry-bg-2', to:"/industry/#renewableEnergy" },
  { id: 2, url: Industry3, className: 'industry-bg-3', to:"/industry/#bankingServices" },
  { id: 3, url: Industry4, className: 'industry-bg-4', to:"/industry/#privateEquity" },
]


const SectionOne = () => {

  const [index, setIndex] = useState(0);

  const transitions = useTransition(index, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);


  const handleContactClick = () => {
    if (typeof document !== 'undefined') {

    window.location = '/contact';
    }
  }

  return (
    <React.Fragment>
      <div id="IndustrySectionOne" className="IndustrySectionOne">
        <div className="IndustrySectionOne-left">
          <div id="IndustrySectionOne-left-head">Delivering Business Ambition across Industries</div>
          <div id="IndustrySectionOne-left-middle">
            <div id="IndustrySectionOne-left-middle-left">
              From Oil & Gas, Renewable Energy, Banking, Private Equity and more, EACIIT has expertise in building performance improvement solutions for a wide range of industries. We leverage our years of expertise, high-impact product and full-service platform to construct powerful, customised solutions that reflect your unique KPIs, business drivers and processes. Our goal is to create easy-to-deploy, high-value solutions that deliver your business ambition with speed, scale and agility.
              <br />
            </div>
          </div>
        </div>

        <div className="IndustrySectionOne-right">
        {
          transitions((style, i) => (
            <animated.div key={i} style={{...style}}>
              <HashLink smooth to={slides[i].to}><img src={slides[i].url} alt="carousel-img" className="industry-image"/></HashLink>
            </animated.div>
          ))
        }
        </div>
      </div>
      <div className="IndustrySectionOne-Menu">
        <Menu pointing secondary>
            <Menu.Item
              name='oilAndGas'
              as = {HashLink}
              smooth
              to='/industry/#oilAndGas'
            >
              <Header as='h4'>Oil and Gas</Header>
            </Menu.Item>
             <Menu.Item
              name='renewableEnergy'
              as = {HashLink}
              smooth
              to='/industry/#renewableEnergy'
            >
              <Header as='h4'>Renewable Energy</Header>
            </Menu.Item>
             <Menu.Item
              name='bankingServices'
              as = {HashLink}
              smooth
              to='/industry/#bankingServices'
            >
              <Header as='h4'>Banking and Financial Services</Header>
            </Menu.Item>
             <Menu.Item
              name='privateEquity'
              as = {HashLink}
              smooth
              to='/industry/#privateEquity'
            >
              <Header as='h4'>Private Equity</Header>
            </Menu.Item>
             <Menu.Item
              name='others'
              as = {HashLink}
              smooth
              to='/industry/#others'
            >
              <Header as='h4'>Others</Header>
            </Menu.Item>
        </Menu>
      </div>
      <div className="IndustrySectionOne-content-wrapper">
        <div id="oilAndGas" className="section-custom oilAndGas">
            <div className="content-wrapper-left">
              <div className="header"> Oil and Gas</div>
              <div className="txt-content">
                <ul>
                  <li>High Impact Solutions across all Segments</li>
                  <li>Efficient planning and forecasting </li>
                  <li>Reduce downtime and cost of upstream activities</li>
                  <li>Linkage between Operational, Field and Financial performance</li>
                  <li>Optimize requirements, resources and supply chain with improvement in profitability and reduction in working capital </li>
                  <li>Maximize segment and channel profitability </li>   
                </ul>
                <div className="discover-link"><HashLink smooth to='/resources/#five' className="text-linked">Discover Customer Success Stories &#8594;</HashLink></div>
              </div>
            </div>
            <div className="content-wrapper-right">
              <img src={OilAndGasImg} className="img-content img-content-oil"  alt="img-content"/>
            </div>
        </div>
        <div id="renewableEnergy" className="section-custom">
            <div className="content-wrapper-left">
              <img src={RenewableEnergyImg} className="img-content img-content-renew" alt="img-content"/>
            </div>
            <div className="content-wrapper-right">
              <div className="header"> Renewable Energy</div>
                <div className="txt-content">
                  <ul>
                    <li>IOT solutions to reduce asset downtime and losses </li>
                    <li>Digital solutions for construction and project management  </li>  
                  </ul>
                  <div className="discover-link"><HashLink smooth to='/resources/#four' className="text-linked">Discover Customer Success Stories &#8594;</HashLink></div>
              </div>
            </div>
        </div>

        <div id="bankingServices" className="section-custom">
            <div className="content-wrapper-left content-bank">
              <div className="header"> Banking and Financial Services</div>
              <div className="txt-content">
                <ul>
                  <li>Integrating customer, product, risk and profit performance to provide a balanced view of growth</li>
                  <li>Improve capital efficiency and ROI of projects and initiatives  </li> 
                </ul>
                <div className="discover-link"><HashLink smooth to='/resources/#one' className="text-linked">Discover Customer Success Stories &#8594;</HashLink></div>
              </div>
            </div>
            <div className="content-wrapper-right">
              <img src={BankingFinancialImg} className="img-content img-content-bank" alt="img-content"/>
            </div>
        </div>

        <div id="privateEquity" className="section-custom">
            <div className="content-wrapper-left">
              <img src={PrivateEquityImg} className="img-content img-content-private" alt="img-content"/>
            </div>
            <div className="content-wrapper-right content-private">
              <div className="header"> Private Equity</div>
                <div className="txt-content">
                  <ul>
                    <li>Accelerate and improve free cash flow returns from operating companies </li>
                    <li>Full range of analytics and dashboards to manage fund performance and reporting   </li>  
                  </ul>
                  <div className="discover-link"><HashLink smooth to='/resources' className="text-linked">Discover Customer Success Stories &#8594;</HashLink></div>
              </div>
            </div>
        </div>
        <div id="others" className="section-custom">
            <div className="content-wrapper-left custom">
              <div className="header"> Others</div>
              <div className="txt-content custom">
                  EACIIT solutions are industry agnostic. While Oil & Gas, Renewable Energy, Banking & Financial Services and Private Equity are sectors we specialize in, we have built solutions for several other industries as well. Retail, construction, manufacturing, human resources, aviation are some such industries. Do contact us if you are looking for a specific solution for your industry.
              </div>
            </div>
            <div className="content-wrapper-right">
              <img src={OthersImg} className="img-content-custom img-content-others" alt="img-content"/>
            </div>
        </div>

        <div id="getContact" className="section-custom">
            <div className="content-wrapper-left custom getContact">
              <div className="header"> Don't see what you're looking for?</div>
              <div className="txt-content custom">
                  Do contact us if you’re looking for a specific solution for your industry.
                  <button className="btn-contact-us" onClick={handleContactClick}>Contact Us</button>
              </div>
            </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SectionOne;
