import React, { useState } from 'react';

import { useTransition, animated, config } from 'react-spring'
import { Menu, Header } from 'semantic-ui-react'
import { HashLink } from 'react-router-hash-link';

import TechnologyStackImg from '../../../resources/technology/technologyStack.png'
import PrivacyImg from '../../../resources/technology/privacy.png'
import SecurityLayerImg from '../../../resources/technology/security.png'
import AccessControlImg from '../../../resources/technology/accessControl.png'
import AuditImg from '../../../resources/technology/audit.png'
import ServiceApproachImg from '../../../resources/technology/serviceApp.png'


import './SectionOne.scss';


const SectionOne = () => {

	const [activeItem, setactiveItem] = useState('TechnologyStack');

	const routeChange = (path) => {
		setactiveItem(path);
	};

	

	return (
		<React.Fragment>
			<div className='TechnologySectionOne'>
				<div className='TechnologySectionOne-head'>
					<h1 className='TechnologySectionOne-header'>
						Speed, Scale & Security 
					</h1>
					<p className='TechnologySectionOne-sub'>
						Built on the back of cutting-edge, open-source technologies the EACIIT Platform gives you the performance, security, and flexibility you need to achieve the outcomes you need.
					</p>
				</div>
			</div>

			<div className="TechnologySectionOne-Menu">
		        <Menu pointing secondary>
		            <Menu.Item
		              name='TechnologyStack'
		              active={
							activeItem === 'TechnologyStack' 
					  }
					  onClick={() => routeChange('TechnologyStack')}
		            >
		              <Header as='h4'>The Core Technology Stack</Header>
		            </Menu.Item>
		             <Menu.Item
		              name='SecurityCompliance'
		              active={
							activeItem === 'SecurityCompliance' 
					  }
					  onClick={() => routeChange('SecurityCompliance')}
		            >
		              <Header as='h4'>Security & Compliance</Header>
		            </Menu.Item>
		             <Menu.Item
		              name='FlexibilityScale'
		              active={
							activeItem === 'FlexibilityScale' 
					  }
					  onClick={() => routeChange('FlexibilityScale')}
		            >
		              <Header as='h4'>Flexibility, Scale & Agility </Header>
		            </Menu.Item>
		        </Menu>
	    	</div>

			{
				activeItem == "TechnologyStack" && 
				<div className="TechnologyStack-content-wrapper content-wrapper">
	        		<div id="TechnologyStack" className="section-custom TechnologyStack">
			            <div className="content-wrapper-left">
			            	<div className="header2">A Tech Stack that Delivers Outstanding Performance</div>
			            	<div className="txt-content">
				                <p>The EACIIT Platform leverages the latest, open-source technologies to enable unprecedented performance, speed, scale, and security. Continuous upgrades and focus on adoption of new, emerging technologies ensures that the platform stays future-proofed.</p>
			             	</div>
			            </div>
			            <div className="content-wrapper-right">
			            	<img src={TechnologyStackImg} className="img-content img-TechnologyStack"  alt="img-content"/>
			            </div>
	        		</div>
        		</div>
			}

			{
				activeItem == "SecurityCompliance" && 
				<div className="SecurityCompliance-content-wrapper content-wrapper">
	     			<h1 className='SecurityCompliance-content-header header'>
						Your Data Stays Safe with Us
						<p className='sub-header'>The EACIIT Platform offers clients best-in-class enterprise security,<br/> compliance, and privacy; in-premise or/and on cloud</p>
					</h1>
	        		<div id="PoliciesAndProtocols" className="section-custom PoliciesAndProtocols">
			            <div className="content-wrapper-left">
			            	<div className="header3">Strong Policies & Protocols</div>
			            	<div className="txt-content">
				                <p>At EACIIT we are committed to implementing:</p>
				                <ul>
				                  <li><span>Industry best practices and security standards around data privacy and protection</span></li>
				                  <li><span>Dedicated DevOps enforced policies for UAT, and production environments: over and above regular security guidelines</span></li>
				                  <li><span>Environment-specific policies to ensure optimum balance between productivity and security</span></li>
				                </ul>
			             	</div>
			            </div>
			            <div className="content-wrapper-right">
			            	<img src={PrivacyImg} className="img-content img-PoliciesAndProtocols"  alt="img-content"/>
			            </div>
	        		</div>

	        		<div id="SecurityLayer" className="section-custom-2 SecurityLayer">
			            <div className="content-wrapper-left">
			            	<img src={SecurityLayerImg} className="img-content img-SecurityLayer"  alt="img-content"/>
			            </div>
			            <div className="content-wrapper-right">
			            	<div className="header3">Multiple Security Layers</div>
			            	<div className="txt-content">
				                <p>Data protection and security is built in at multiple levels:</p>
				                <ul>
				                  <li><span>Architecture and network level</span></li>
				                  <li><span>Infrastructure level (including Encryption@Rest) </span></li>
				                  <li><span>User access level </span></li>
				                  <li><span>Automated backups and handing of data to avoid need for manual intervention or handling of data in UAT or production </span></li>
				                </ul>
			             	</div>
			            </div>
	        		</div>

	        		<div id="AccessControl" className="section-custom AccessControl">
			            <div className="content-wrapper-left">
			            	<div className="header3">Role-Based Access Control & SSO</div>
			            	<div className="txt-content">
				                <p>The EACIIT platform offers:</p>
				                <ul>
				                  <li><span>Secure Single Sign On: Allow users to log in conveniently and securely with SSO.</span></li>
				                  <li><span>Authentication: Use EACIIT’S built-in login functionality with customized password policies or use your own identity provider</span></li>
				                  <li><span>Role-based Authorization: Assign users custom roles. Data and functionality access determined as per assigned role permissions</span></li>
				                </ul>
			             	</div>
			            </div>
			            <div className="content-wrapper-right">
			            	<img src={AccessControlImg} className="img-content img-AccessControl"  alt="img-content"/>
			            </div>
	        		</div>
	        		<div id="Audit" className="section-custom-2 Audit">
			            <div className="content-wrapper-left">
			            	<img src={AuditImg} className="img-content img-Audit"  alt="img-content"/>
			            </div>
			            <div className="content-wrapper-right">
			            	<div className="header3">Full Audit Traceability </div>
			            	<div className="txt-content">
				                <ul>
				                  <li><span>Detailed logging and traceability enabling</span></li>
				                  <li><span>Internal Audits</span></li>
				                  <li><span>External Audits of UAT, Production environment </span></li>
				                  <li><span>SAST & DAST scans </span></li>
				                </ul>
			             	</div>
			            </div>
	        		</div>
	        	</div>
			}

	     	{
	     		activeItem == "FlexibilityScale" && 
	     		<div className="FlexibilityScale-content-wrapper content-wrapper">
	     			<h1 className='FlexibilityScale-content-header header'>
						Flexibility, Scale & Agility Asssured
						<p className='sub-header'>On-premise or on cloud, all solutions built on the EACIIT Platform are <br/>designed to offer you flexibility, scale and agility. Standard features include:</p>
					</h1>
	        		<div className="section-content">
			            <div className="box-list">
			            	<div className="box">
			            		<div className="box-header">Configurable, Flexible Elements</div>
			            		<div className="box-content">The platform leverages the functionalities of its pre-built EACIIT Data Browser, ECFM, User Management and Chat modules to provide low-code, flexible elements that can be configured and modified as per your requirements.</div>
			            	</div>
			            	<div className="box">
			            		<div className="box-header">Scalability Provisions </div>
			            		<div className="box-content">Our solution architectures are specifically designed to scale, both horizontally and vertically. This ensures that we are able to provide the support needed for geographically-distributed implementations involving real-time big data, as and when you need it, rapidly.</div>
			            	</div>
			            </div>
			            <div className="box-list multi-box">
			            	<div className="box">
			            		<div className="box-header">Flexible Deployment</div>
			            		<div className="box-content">You choose how you want to deploy the solution – on-premise or on cloud. We are cloud agnostic and also have the capability to support on-premise deployment.</div>
			            	</div>
			            	<div className="box">
			            		<div className="box-header">Fast-Track Implementation</div>
			            		<div className="box-content">Our rapid solution development and implementation approach allows us to build and customize solutions at unprecedented speeds – think weeks, not months. </div>
			            	</div>
			            	<div className="box">
			            		<div className="box-header">Transparent Design Process</div>
			            		<div className="box-content">No black boxes! We believe in following a transparent process to give you visibility in solution design</div>
			            	</div>
			            </div>
	        		</div>
	        		<div className="content">
			            	<img src={ServiceApproachImg} className="img-content img-approach"  alt="img-content"/>
			        </div>
	        	</div>
	     	}

        	

        	

		</React.Fragment>
)}

export default SectionOne;