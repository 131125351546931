import React from 'react';
import SectionOne from './SectionOne/index.jsx';
import SectionTwo from './SectionTwo/index.jsx';
import SectionThree from './SectionThree/index.jsx';
import SectionFour from './SectionFour/index.jsx';

import './platform.scss';

const PlatformComponent = () => {
	React.useEffect(() => {
		window.scrollTo(0,0)
	},[])
	return (
		<>
			<SectionOne />
			<SectionFour />
		</>
	);
};

export default PlatformComponent;
