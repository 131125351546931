import React from "react";
import "./SectionOne.scss";

const SectionOne = () => {
    return (
        <div id="DataQualityToolSectionOne" className="DataQualityToolSectionOne">
            <div className="DataQualityToolSectionOne-head">
                <h1 className="DataQualityToolSectionOne-header">Measure and Enhance Data Quality</h1>
                <p className="DataQualityToolSectionOne-sub">Continuous identification , assessment and remediation of data for sustained business use</p>
                {/* <button className="schedule-demo-btn register-now-btn">Register Now</button> */}
            </div>
        </div>
    );
};

export default SectionOne;
