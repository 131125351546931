import React from 'react';
import SectionFive from './SectionFive/index.jsx';
import SectionFour from './SectionFour/index.jsx';
import SectionOne from './SectionOne/index.jsx';
import SectionSeven from './SectionSeven/index.jsx';
import SectionSix from './SectionSix/index.jsx';
import SectionThree from './SectionThree/index.jsx';
import SectionTwo from './SectionTwo/index.jsx';
import './resources.scss';

const ResourcesComponent = () => {

	React.useEffect(() => {
		window.scrollTo(0,0)
		document.getElementById("navBar").scrollIntoView()
	},[])

	return (
		<div className="CaseStudies">
			<SectionOne />
			<SectionTwo />
			<SectionThree />
			<SectionFour />
			<SectionFive />
			<SectionSix />
			<SectionSeven />
		</div>
	);
};

export default ResourcesComponent;
