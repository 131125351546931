import React, {Component} from 'react';
import './sectionSix.scss';
import Spinner from '../../../resources/toggle-thumbnail.png';
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';
import checkIcon from '../../../resources/check-icon.png';

class SectionSix extends Component {
  constructor(props) {
	    super(props);
	    this.state = {
	      visibility: false,
        }
   }
   render(){
   	return (
		<div id='sectionSix-wrapper'>
			<div id='sectionSix'>
				<div id='sectionSix-head'>Technology Differentiation</div>
				<div className='sectionSix-sub-head'>Progressive Data Engineering and AI</div>
				<div className="sectionSix-content">
					<div id='sectionSix-left-middle'>
						<div id='sectionSix-left-middle-left'>
							<VisibilitySensor partialVisibility={true} onChange={(isVisible) => { this.setState({visibility: isVisible}) }}>
						         <ul className="list-delivers">
											<Fade bottom duration={1000} spy={this.state.visibility}>
												<li>
													<img src={checkIcon} alt='Check icon' className='check-icon'/> 
													Scalable and modular architecture
												</li>
											</Fade>
											<Fade bottom duration={1000} delay={1000} spy={this.state.visibility}>
												<li>
													<img src={checkIcon} alt='Check icon' className='check-icon'/>
													No limitations on data size, structure, users
												</li>
											</Fade>
											<Fade bottom duration={1000} delay={2000} spy={this.state.visibility}>
												<li>
													<img src={checkIcon} alt='Check icon' className='check-icon'/>
													Custom visualization and complex analysis
												</li>
											</Fade>
											<Fade bottom duration={1000} delay={3000} spy={this.state.visibility}>
												<li>
													<img src={checkIcon} alt='Check icon' className='check-icon'/>
													Rapid change delivers 98%+ accuracy in AI and analytical models
												</li>
											</Fade>
											<Fade bottom duration={1000} delay={4000} spy={this.state.visibility}>
												<li>
													<img src={checkIcon} alt='Check icon' className='check-icon'/>
													Instant Messaging and Collaboration tools
												</li>
											</Fade>
										</ul>
					        </VisibilitySensor>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
   }
	
};

export default SectionSix;
