import React from 'react';
import './SectionOne.scss';
import section1arrow from '../../../resources/arrow.png';

const SectionOne = () => {
	return (
		<div className='ResourcesSectionOne'>
			<h1 className='ResourcesSectionOne-header'>
				Discover How Organizations Use
				<br />
				EACIIT Solutions To Drive Improvement
			</h1>
			<p className='ResourcesSectionOne-sub-header'>
				EACIIT solutions are being used by clients across the globe to drive real, quantifiable improvements in their organizations. <br/>
				Explore some of our customer success stories to discover how EACIIT can help you enhance enterprise value.
			</p>
			<div className='ResourcesSectionOne-cards-list'>
				<div className='ResourcesSectionOne-cards-list-row'>
					<a href='#one' className='one'>
						<h2>Global Bank</h2>
						<p>
							Global Bank uses EACIIT solutions to create visibility and
							alignment on initiative and outcomes
						</p>
						<img src={section1arrow} alt="section-img-arrow"/>
					</a>
					<a href='#two' className='two'>
						<h2>Oil & Gas Super Major</h2>
						<p>
							Oil & Gas Super Major uses ECFM to rapidly reduce time and cost
							upstream activities
						</p>
						<img src={section1arrow} alt="section-img-arrow"/>
					</a>
					<a href='#three' className='three'>
						<h2>FMCG Major, Global Brand</h2>
						<p>
							Consumer Products Global Brand used EACIIT platform to analyze
							opportunities for growth in a mature market
						</p>
						<img src={section1arrow} alt="section-img-arrow"/>
					</a>
				</div>
				<div className='ResourcesSectionOne-cards-list-row'>
					<a href='#four' className='four'>
						<h2>Renewable Energy Company</h2>
						<p>
							Renewable Energy company uses EACIIT solutions to reduce asset
							downtime
						</p>
						<img src={section1arrow} alt="section-img-arrow"/>
					</a>
					<a href='#five' className='five'>
						<h2>Oil & Gas Fintech</h2>
						<p>
							Oil & Gas Fintech uses EACIIT to provide investors operational
							visibility on assets under management
						</p>
						<img src={section1arrow} alt="section-img-arrow"/>
					</a>
					<a href='#six' className='six'>
						<h2>Oil & Gas Global Service Provider</h2>
						<p>
							Oil & Gas Global Service Provider uses EACIIT to realign legacy
							businesses to growth oriented models
						</p>
						<img src={section1arrow} alt="section-img-arrow"/>
					</a>
				</div>
			</div>
		</div>
	);
};

export default SectionOne;
