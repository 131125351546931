import React from 'react';
import './SectionFour.scss';
import ImgSrc from '../../../resources/caseStudy/03-.png';

const SectionFour = () => {
	return (
		<div className='ResourcesSectionFour' id='three'>
			<div className="ResourcesSectionFour-content">
				<div className='ResourcesSectionFour-left'>
					<div className="ResourcesSectionFour-img">
						<img src={ImgSrc} alt='Market Calculation Img' className="ResourcesSectionFour-market" />
					</div>
				</div>
				<div className='ResourcesSectionFour-right'>
					<h2>
						EACIIT solution enables effective strategy formulation: FMCG major achieves 20% EBITDA improvement
					</h2>	
					<p>
						Infusion of 20% growth rate in a mature market with mature products:
						This was the challenge faced by the consumer products global brand.
						EACIIT created a solution that integrated the client’s primary and
						secondary sales databases and enabled analysis of all sales at the SKU
						and outlet level across multiple dimensions and hierarchies.
					</p>
					<p>
						The solution also provided activity-based cost allocations to analyze
						profitability across channels, key accounts and brands was also. The
						improved visibility and availability of actionable insights
						facilitated effective strategy formulation. The company was able to
						drive 20% improvement in EBITDA margin
					</p>
				</div>
			</div>
		</div>
	);
};

export default SectionFour;
