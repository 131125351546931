import React from 'react';
import SectionOne from './SectionOne/index.jsx';
import SectionTwo from './SectionTwo/index.jsx';

import './smartCommand.scss';

export class SmartCommand extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div className='SmartCommand'>
				<section>
					<SectionOne />
				</section>
				<section>
					<SectionTwo />
				</section>
			</div>
		);
	}
}

export default SmartCommand;
